import Shell from "../../../components/specific/Shell.tsx";
import {User} from "../../../api/accessControl/User.ts";
import ClientsList from "./ClientsList.tsx";
import {useEffect, useState} from "react";
import {BackendApi} from "../../../api/api.ts";

export default function PartnerDashboard({currentUser}: { currentUser: User }) {
    const greetingEmojis = ['✨', '👋', '🙌', '🚀']
    const selectedGreetingEmoji = greetingEmojis[Math.floor(Math.random() * greetingEmojis.length)];

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [myClients, setMyClients] = useState<any>([]);

    const api = new BackendApi(currentUser.getToken())

    async function onLoad() {
        api.get('partner/my-clients', {business_id: currentUser.businesses[0]}).then(data => data.json()).then(data => setMyClients(data)).then(() => setIsLoading(false))
        console.log(myClients)
    }

    useEffect(() => {
        onLoad()
    }, []);

    if (!myClients) return;


    return (
        <Shell currentUser={currentUser} isLoading={isLoading}>
            <div className="w-full flex items-center mb-8 justify-between">
                <h1 className="weight-600 text-3xl">Hello {currentUser.firstName} {selectedGreetingEmoji}</h1>
                <button className="text-base bg-primary text-white px-4 py-2 rounded-md mt-4"
                        onClick={() => false}>Add client
                </button>

            </div>
            <ClientsList clients={myClients} currentUser={currentUser}></ClientsList>
        </Shell>
    )
}
