import {User} from "../../../../api/accessControl/User.ts";
import Insights from "../Insights.tsx";
import Shell from "../../../../components/specific/Shell.tsx";

export default function InsightsPage({currentUser}: { currentUser: User }) {
    return (
        <Shell currentUser={currentUser}>
            <h1 className="weight-600 text-3xl mb-6 ">Insights</h1>
            <Insights currentUser={currentUser}></Insights>
        </Shell>
    )
}
