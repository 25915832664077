import {XMarkIcon} from "@heroicons/react/24/outline";

export default function Banner({content, actionUrl}: { content: string, actionUrl: string }) {

    return <div
        className="pointer-events-auto flex items-center justify-between gap-x-6 bg-red-400 px-6 py-2.5 rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5">
        <div
            className="text-sm/6 text-white flex flex-col gap-y-3 md:flex-row items-left md:items-center gap-x-4 justify-between w-full">
            <div>{content}</div>
            <a href={actionUrl} className="bg-red-300 hover:opacity-70 text-white px-2 py-1 rounded-md text-nowrap">
                Bekijken &rarr;
            </a>
        </div>
    </div>
}
