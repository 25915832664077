import {createTable, TableHeaderSpec} from "../../../../../components/table/CreateTable.tsx";
import {SecondaryButton} from "../../../../../components/general/SecondaryButton.tsx";

export interface CategorizationRule {
    id: string,
    name: string,
    tql_expression: string,
    target_category: string,
    enabled: boolean,
    created_by: string,
    updated_at: string,
    created_at: string,
    first_name: string
    last_name: string
}

interface Props {
    data: Array<CategorizationRule>,
    onEditClick: (rule: CategorizationRule) => void
}

export function RulesTable({data, onEditClick}: Props) {
    const tableSpec: TableHeaderSpec<CategorizationRule> = {
        columns: [
            {
                name: 'Name',
                field: 'name'
            },
            {
                name: 'TQL Expression',
                field: 'tql_expression',
                customRender: (row: CategorizationRule) => (
                    <div className="text-sm text-gray-500 font-mono">{row.tql_expression.slice(0, 100)}</div>
                )
            },
            {
                name: 'Target category',
                field: 'target_category'
            },
            {
                name: 'Created at',
                field: 'created_at',
                customRender: (row: CategorizationRule) => (
                    <div className="text-sm text-gray-500">{new Date(row.created_at).toLocaleString()}</div>
                )
            },
            {
                name: 'Created by',
                field: 'created_by',
                customRender: (row: CategorizationRule) => (
                    <div className="flex items-center gap-x-2">
                        <div className="rounded-full bg-gray-100 p-2">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                 strokeWidth={1.5} stroke="currentColor" className="size-4">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"/>
                            </svg>
                        </div>
                        <div className="flex-auto">
                            <div className="text-sm text-gray-500">{row.first_name} {row.last_name}</div>
                        </div>
                    </div>

                )
            },
            {
                name: '',
                field: '_',
                customRender: (row: CategorizationRule) => (
                    <div className="text-right">
                        <SecondaryButton title={'Edit'} onClick={() => onEditClick(row)}
                                         className="bg-transparent py-1 text-gray-500 hover:text-gray-700"/>
                    </div>
                )
            }
        ]
    }

    const TableComponent = createTable<CategorizationRule>(data, tableSpec)

    return (
        <>
            <TableComponent/>
        </>
    )
}
