import {User} from "../../../../../api/accessControl/User.ts";
import LoadingFrame from "../../../../../components/LoadingFrame.tsx";
import {PlusIcon} from "@heroicons/react/16/solid/index";
import {useEffect, useRef, useState} from "react";
import {Editor} from "@monaco-editor/react";
import {SaveIcon} from "lucide-react";
import {BackendApi} from "../../../../../api/api.ts";
import {BaseTransaction} from "../../../../../api/baseTransactions/BaseTransaction.ts";
import {FormError} from "../../../../../components/form/FormError.tsx";
import {Notifications, UseNotifications} from "../../../../../lib/NotificationDispatcher.tsx";
import {CategorizationRule, RulesTable} from "./RulesTable.tsx";
import {TransactionsAdminTable} from "../TransactionsAdminTable.tsx";
import {PaginationState, SortingState} from "../../../../../components/table/CreateTable.tsx";
import {TagIcon} from "@heroicons/react/24/outline";
import {BaseTransactionsRepository} from "../../../../../api/baseTransactions/BaseTransactionsRepository.ts";
import {useSearchParams} from "react-router-dom";
import WideDrawer from "../../../../../components/general/WideDrawer.tsx";
import {SecondaryButton} from "../../../../../components/general/SecondaryButton.tsx";
import {CategoriesListBox} from "../baseTransactions/CategoriesListBox.tsx";
import ButtonWithDropDown from "../../../../../components/general/ButtonWithDropDown.tsx";
import Shell from "../../../../../components/specific/Shell.tsx";
import {PrimaryButton} from "../../../../../components/general/PrimaryButton.tsx";

export default function CategorizationRulesPage({currentUser}: { currentUser: User }) {

    const [params, _] = useSearchParams()

    const [isEditorOpen, setIsEditorOpen] = useState<boolean>(true);
    const [categoryToApply, setCategoryToApply] = useState<string>('Uncategorized');

    const [error, setError] = useState<string>('');

    const api = new BackendApi(currentUser.getToken());

    const paginationState = useState<any>(null)
    const sortingState = useState<any>(null)

    const [previewTransactions, setPreviewTransactions] = useState<Array<BaseTransaction>>([]);
    const [isPreviewTransactionsLoading, setIsPreviewTransactionsLoading] = useState<boolean>(false);
    const [previewTransactionsCount, setPreviewTransactionsCount] = useState<number>(0);

    const [ruleName, setRuleName] = useState<string>("");
    const [ruleId, setRuleId] = useState<string>("");
    const [querySuggestion, setQuerySuggestion] = useState<string>("");
    const [isQuerySuggestionLoading, setIsQuerySuggestionLoading] = useState<boolean>(false);

    const editorRef = useRef(null);

    function handleEditorDidMount(editor, monaco) {
        editorRef.current = editor;
    }

    const [notifications, dispatch] = UseNotifications()

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [rules, setRules] = useState<Array<CategorizationRule>>([]);

    async function onLoad() {
        await api.get('transactions/transaction-categorization-rule/get-all').then(data => data.json()).then(data => {
            setRules(data)


        }).then(() => {
            setIsLoading(false)

        }).catch(err => console.log(err))

        if (!params.get('edit_rule_id'))
            return

        const rule = rules.find(rule => rule.id === params.get('edit_rule_id'))

        setCategoryToApply(rule.target_category)
        setRuleName(rule.name)
        setRuleId(rule.id)
        setIsEditorOpen(true)
        setEditorDefaultValue(rule.tql_expression)
        setPreviewTransactions([])
        setPreviewTransactionsCount(0)
    }

    useEffect(() => {
        onLoad()
    }, [])

    function handleSave() {
        const body = {
            name: ruleName,
            tql_expression: getExpression(),
            target_category: categoryToApply,
            enabled: true,
        }

        if (ruleId)
            body['id'] = ruleId

        api.post('transactions/transaction-categorization-rule/save', body).then(async data => {
            if (!data.ok) {
                dispatch({
                    title: 'Error saving rule',
                    description: 'Something went wrong: ' + (await data.json())?.detail,
                    type: 'error',
                })
                return
            }
            setIsEditorOpen(false)
            await onLoad()
            dispatch({
                title: 'Rule saved',
                description: 'The rule has been saved successfully',
                type: 'success',
            })
        }).catch(err => {
            dispatch({
                title: 'Error saving rule',
                description: 'Something went wrong. Please contact us via hello@pocketcfi.io | CODE: ERR52436',
                type: 'error',
            })
        })
    }

    const [editorDefaultValue, setEditorDefaultValue] = useState<string>('');

    function handleOnEditClick(rule: CategorizationRule) {


        setCategoryToApply(rule.target_category)
        setRuleName(rule.name)
        setRuleId(rule.id)
        setIsEditorOpen(true)
        setEditorDefaultValue(rule.tql_expression)
        setPreviewTransactions([])
        setPreviewTransactionsCount(0)
    }

    function getExpression() {
        return editorRef.current.getValue()
    }

    function pullPreviewTransactions(page: number = 1, pageSize: number = 100, orderBy: string = null, orderDescending: boolean = null) {
        setIsPreviewTransactionsLoading(true)
        setPreviewTransactions([])
        setError("")

        const baseBody = {
            expression: getExpression(),
            page: page,
            page_size: pageSize,
        }

        let body = null

        if (orderBy) {
            body = {
                order_by: orderBy,
                order_descending: orderDescending,
                ...baseBody
            }
        }


        api.get('tql/preview', body || baseBody).then(data => {
            if (data.status == 404) {
                setError("No transactions found")
                setIsPreviewTransactionsLoading(false)
            }
            return data
        }).then(data => data.json()).then(data => {
            console.log(data)
            setPreviewTransactions(data.results.map(BaseTransaction.fromApiResponse))
            setPreviewTransactionsCount(data?.count || 0)
        }).then(() => setIsPreviewTransactionsLoading(false)).catch(err => {
            dispatch({
                title: 'Error',
                description: 'The rule is invalid. Please, check the TQL expression.',
                type: 'error',
            })
            setIsPreviewTransactionsLoading(false);
        })
    }

    function onQueryUpdate(pagination: PaginationState<BaseTransaction>, sorting: SortingState) {
        const sortingFieldConversion = {
            'transactionId': 'transaction_id',
            'relationName': 'relation_name',
            'relationIBAN': 'relation_iban',
            'amount': 'amount',
            'value': 'amount',
            'flow': 'flow',
            'category': 'category',
            'description': 'description',
            'date': 'date',
        }

        pullPreviewTransactions(pagination?.currentPage, pagination?.pageSize, sortingFieldConversion[sorting?.orderBy], sorting?.orderDescending)
    }


    return (
        <div>
            <Notifications notifications={notifications}/>
            <WideDrawer open={isEditorOpen} title={'Rule editor'} updateOpenState={setIsEditorOpen}>
                <div>
                    <div className="relative mt-2 my-2 ">
                        <input
                            id="name"
                            name="name"
                            type="text"
                            placeholder="My Rule"
                            autoComplete="hidden"
                            onChange={(e) => setRuleName(e.target.value)}
                            defaultValue={ruleName}
                            className="peer block border-0  py-1.5 text-gray-900 focus:ring-0 ring-0 sm:text-xl hover:underline hover:decoration-dashed focus:outline-none"
                        />


                    </div>
                </div>

                <div className="border border-gray-200 rounded-md bg-white p-4 text-md mb-4 font-mono">
                    <Editor height="20vh" defaultLanguage="python" defaultValue={editorDefaultValue}
                            onMount={handleEditorDidMount}
                            options={{
                                fontSize: 18,
                            }}
                    />
                    <div className="mt-2 flex items-center gap-1 justify-between">
                        {isQuerySuggestionLoading && <img
                            src="/loader-slow.gif" className={"h-8 w-8"} alt=""/>}
                        <input
                            id="suggestion"
                            name="test"
                            type="text"
                            onChange={(e) => setQuerySuggestion(e.target.value)}
                            defaultValue={querySuggestion}
                            autoComplete="off"
                            placeholder="Type your query suggestion"
                            className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:outline-none sm:text-sm/6

                            "
                        />
                        <SecondaryButton onClick={() => {
                            setIsQuerySuggestionLoading(true)
                            api.get('tql/query-from-suggestion', {
                                suggestion: querySuggestion
                            }).then(data => data.json()).then(data => {
                                editorRef.current.setValue(data.result)
                            }).then(() => {
                                setIsQuerySuggestionLoading(false)
                            })

                        }} title={'Generate'}
                                         className="bg-gradient-to-r from-indigo-400 via-purple-400 to-pink-400 py-2 text-white"/>
                    </div>
                    {error && <FormError error={error}/>}
                </div>
                <div className="border border-gray-200 rounded-md bg-white p-4 text-md mb-4 flex items-center">
                    <span>When condition applies, automatically categorize transactions as:</span> <CategoriesListBox
                    defaultCat={categoryToApply} onChangeFunc={setCategoryToApply} flow={'both'}/>
                </div>
                <div className="flex items-center justify-between">
                    <SecondaryButton title={'Preview'} onClick={() => pullPreviewTransactions()}/>
                    <ButtonWithDropDown title={'Save'} type={'primary'} icon={SaveIcon} onClick={() => handleSave()}
                                        dropdownItems={[
                                            {
                                                title: 'Save and apply to all',
                                                icon: TagIcon,
                                                onClick: async () => {
                                                    const api = new BackendApi(currentUser.getToken());
                                                    const baseTransactionsRepository = new BaseTransactionsRepository(api);
                                                    alert('Are you sure you want to categorize ' + previewTransactionsCount + ' transactions as ' + categoryToApply + '?')
                                                    baseTransactionsRepository.categorizeBatch(previewTransactions, categoryToApply)
                                                    handleSave()
                                                }
                                            }
                                        ]}/>
                    {/*<PrimaryButton title={'Save'} onClick={} icon={SaveIcon}/>*/}

                </div>

                <LoadingFrame isLoading={isPreviewTransactionsLoading}>
                    <div className="border border-gray-200 rounded-md bg-white p-4 text-md mt-8">
                        <h2 className={'text-2xl mt-2 mb-6'}>{previewTransactionsCount} Transactions</h2>
                        <TransactionsAdminTable
                            data={previewTransactions}
                            onQueryUpdate={onQueryUpdate}
                            totalRecords={previewTransactionsCount}
                            paginationState={paginationState}
                            sortingState={sortingState}
                        />
                    </div>
                </LoadingFrame>

            </WideDrawer>
            <Shell currentUser={currentUser} isLoading={isLoading}>
                <div className="flex items-center justify-between">
                    <h1 className="weight-600 text-3xl mb-6 ">Categorization Rules</h1>
                    <PrimaryButton title={'Add rule'}
                                   onClick={() => setIsEditorOpen(true)}
                                   icon={PlusIcon}/>
                </div>
                <div>
                    <RulesTable data={rules} onEditClick={(row) => handleOnEditClick(row)}/>
                </div>
            </Shell>
        </div>
    )
}
