import {User} from "../../../../../api/accessControl/User.ts";
import Shell from "../../../../../components/specific/Shell.tsx";
import {Transaction} from "../../TransactionsTable.tsx";
import {BackendApi} from "../../../../../api/api.ts";
import React, {useEffect, useRef, useState} from "react";
import NarrowDrawer from "../../../../../components/general/NarrowDrawer.tsx";
import {PrimaryButton} from "../../../../../components/general/PrimaryButton.tsx";
import {useSearchParams} from "react-router-dom";
import {capitalize} from "../../../../../lib/TFICLib.ts";
import {Dialog, DialogBackdrop, DialogPanel, DialogTitle} from "@headlessui/react";
import {Filter, FilterOperation, FilterService} from "./FilterService.ts";
import {BaseTransactionsRepository} from "../../../../../api/baseTransactions/BaseTransactionsRepository.ts";
import {BaseTransaction} from "../../../../../api/baseTransactions/BaseTransaction.ts";
import {TransactionsTable} from "./TransactionsTable.tsx";
import {PaginationState, SortingState} from "../../../../../components/table/CreateTable.tsx";
import {CustomerBankAccount} from "../../../../../api/customerBankAccounts/CustomerBankAccount.ts";
import {CustomerBankAccountRepository} from "../../../../../api/customerBankAccounts/CustomerBankAccountRepository.ts";
import TFICCache from "../../../../../lib/TFICCache.ts";


function renderFilter(filter: Filter) {
    const columnName = capitalize(filter.columnId)
    if (filter.operation === FilterOperation.BETWEEN) {
        return <span>{columnName} tussen {filter.values[0]} and {filter.values[1]}</span>
    }

    if (filter.operation === FilterOperation.EQUALS) {
        return <span>{columnName} is {filter.values[0]}</span>
    }

    if (filter.operation === FilterOperation.GREATER_THAN) {
        return <span>{columnName} is meer dan {filter.values[0] / 100}</span>
    }

    if (filter.operation === FilterOperation.LESS_THAN) {
        return <span>{columnName} is minder dan {filter.values[0] / 100}</span>
    }
}

function WrongTransactionCategoryModal({transaction, onClose, isOpen, onSubmit}: {
    transaction: Transaction,
    onClose: () => void,
    isOpen: boolean,
    onSubmit?: (transaction: Transaction, feedback: string | null) => void
}) {

    const textAreaRef = useRef();

    return (
        <Dialog open={isOpen} onClose={onClose} className="relative z-10">
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div>
                            <div className="">
                                <DialogTitle as="h3" className="text-base font-semibold text-gray-900">
                                    Waarom is de categorie onjuist?
                                </DialogTitle>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">
                                        Laat ons weten waarom de categorie onjuist is. We zullen je feedback bekijken en
                                        de categorie indien nodig aanpassen.
                                    </p>
                                </div>
                                <div>
                                    <div className="mt-4">
                                        <textarea
                                            id="comment"
                                            name="comment"
                                            rows={4}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm/6"
                                            defaultValue={''}
                                            ref={textAreaRef}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                            <button
                                type="button"
                                onClick={() => {
                                    // @ts-ignore
                                    onSubmit(transaction, textAreaRef.current.value ?? "")
                                }
                                }
                                className="inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:opacity-70 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary sm:col-start-2"
                            >
                                Versturen
                            </button>
                            <button
                                type="button"
                                data-autofocus="true"
                                onClick={() => onSubmit(transaction, null)}
                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                            >
                                Send without feedback
                            </button>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    )
}


async function pullTransactions(transactionRepository: BaseTransactionsRepository, businessId: string, sorting: SortingState, filters) {
    const baseTransactions = await transactionRepository.getBusinessBankTransactions(
        businessId,
        0,
        100000,
        sorting?.orderBy,
        sorting?.orderDescending
    )

    const transactions = baseTransactions.results.map((original: BaseTransaction) => ({
        id: original.id,
        description: original.description,
        category: original.category,
        bookingDate: original.date,
        amount: original.amount,
        flow: original.flow,
        relationName: original.relationName,
        relationIBAN: original.iban,
        value: original.value,
        bankAccountId: original.bankAccountId
    }))

    let finalTransactions = transactions

    if (filters) {
        const filterService = new FilterService(transactions)
        finalTransactions = filterService.apply(filters)
    }
    return finalTransactions;
}

function buildFetchFunction(api: BackendApi): (key: string) => Promise<string> {
    async function fetchBankLogo(bankName: string): Promise<string> {
        const response = await api.get('bank/logo', {bank_id: bankName});
        const json = await response.json();
        return json.logo_url;
    }

    return fetchBankLogo;

}

const bankLogoCache = new TFICCache<string>();

async function syncBankAccounts(
    api: BackendApi,
    bankAccounts: CustomerBankAccount[],
    setBankAccounts: (accounts: CustomerBankAccount[]) => void
): Promise<void> {
    const uniqueBanks = new Set(bankAccounts.map(bankAccount => bankAccount.institutionName));


    bankLogoCache.withFetchFunction(buildFetchFunction(api))
    for (const bankName of uniqueBanks) {
        await bankLogoCache.get(bankName);
    }

    const bankAccountsWithLogos = await Promise.all(bankAccounts.map(async bankAccount => {
        const logo = await bankLogoCache.get(bankAccount.institutionName);
        return bankAccount.withLogoUrl(logo);
    }))

    setBankAccounts(bankAccountsWithLogos);
}


export function TransactionsPage({currentUser}: { currentUser: User }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
    const [isWrongCategoryModalTransactionOpen, setIsWrongCategoryModalTransactionOpen] = useState<Transaction>(null)
    const tablePaginationState = useState<PaginationState<Transaction>>();
    const tableSortingState = useState();

    const [transactions, setTransactions] = useState<Array<Transaction>>([]);
    const [bankAccounts, setBankAccounts] = useState<Array<CustomerBankAccount>>([]);

    const startDate = useRef();
    const endDate = useRef();
    const flowFilter = useRef();
    const amountComparatorType = useRef();
    const amountComparisonAmount = useRef();

    const filters = JSON.parse(searchParams.get('filters')) || [];

    function addFilters(filtersToAdd: Array<Filter>) {
        let filtersLocal = filters;
        filtersLocal = [...filtersToAdd, ...filtersLocal]
        window.location.replace(`?${new URLSearchParams({filters: JSON.stringify(filtersLocal), ...searchParams})}`)
    }

    function removeFilter(filter: Filter) {
        let filtersLocal = filters;
        filtersLocal = filtersLocal.filter((oldFilter) => oldFilter !== filter)
        window.location.replace(`?${new URLSearchParams({filters: JSON.stringify(filtersLocal), ...searchParams})}`)
    }

    const api = new BackendApi(currentUser.getToken())

    async function onLoad(sorting: SortingState | null = null) {
        setTransactions([])

        const transactionRepository = new BaseTransactionsRepository(api)

        const transactions = await pullTransactions(
            transactionRepository,
            currentUser.businesses[0],
            sorting,
            filters
        )

        setTransactions(transactions)

        const customerBankAccountRepository = new CustomerBankAccountRepository(api)
        const bankAccounts = await customerBankAccountRepository.getMyBankAccounts(currentUser.businesses[0], false);

        await syncBankAccounts(api, bankAccounts, setBankAccounts)

        setIsLoading(false)
    }

    useEffect(() => {
        onLoad()
    }, []);


    function handleOnWrongCategoryClick(transaction: Transaction) {
        setIsWrongCategoryModalTransactionOpen(transaction)
    }

    function handleOnWrongCategorySubmit(transaction: Transaction, feedback: string | null) {
        api.post('transactions/give-category-feedback', {
            transaction_id: transaction.id,
            feedback: feedback
        }).then(data => {
            onLoad()
            setIsWrongCategoryModalTransactionOpen(null)
        }).catch(err => {
            alert('Something went wrong. Please contact us via hello@pocketcfi.io | CODE: ERR52436')
        }).then(() => {
            setIsWrongCategoryModalTransactionOpen(null)
        })

    }


    return (
        <>
            <WrongTransactionCategoryModal transaction={isWrongCategoryModalTransactionOpen}
                                           isOpen={!!isWrongCategoryModalTransactionOpen}
                                           onClose={() => setIsWrongCategoryModalTransactionOpen(null)}
                                           onSubmit={handleOnWrongCategorySubmit}/>
            <NarrowDrawer open={isFilterDrawerOpen} updateOpenState={setIsFilterDrawerOpen} title={'Filters'}>
                <div className={'flex flex-col gap-3 mb-8'}>
                    <div className="flex items-center gap-x-2">
                        <span className={'block bg-gray-100 py-0.5 px-2 rounded-md'}>Datum</span> is
                        <span className='mr-2'>tussen</span>
                        <input type="date" ref={startDate}
                               className="flex-1 border-0 bg-transparent py-1 px-2 text-gray-900 border-2 border-gray-200 rounded-md"/>
                        <span className='mx-2'>en</span>
                        <input type="date" ref={endDate}
                               className="flex-1 border-0 bg-transparent py-1 px-2 text-gray-900 border-2 border-gray-200 rounded-md"/>
                    </div>
                    <div className="flex items-center gap-x-2">
                        <span className={'block bg-gray-100 py-0.5 px-2 rounded-md'}>Flow</span> is
                        <select
                            id="legal_entity_type"
                            name="legal_entity_type"
                            ref={flowFilter}
                            className="px-2 block focus:outline-none  rounded-md border-0 py-1.5 pr-15 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-primary sm:text-sm sm:leading-6"
                        >
                            <option value="any">beide</option>
                            <option value="inflow">inkomend</option>
                            <option value="outflow">uitgaand</option>

                        </select>
                    </div>
                    <div className="flex items-center gap-x-2">
                        <span className={'block bg-gray-100 py-0.5 px-2 rounded-md'}>Bedrag</span> is
                        <select
                            id="legal_entity_type"
                            name="legal_entity_type"
                            ref={amountComparatorType}
                            className="px-2 focus:outline-none block rounded-md border-0 py-1.5 pr-15 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-primary sm:text-sm sm:leading-6"
                        >
                            <option value="gt">meer dan</option>
                            <option value="lt">minder dan</option>

                        </select>
                        <input type="number" ref={amountComparisonAmount}
                               className=" border-0 bg-transparent py-0.5 px-2 text-gray-900 border-2 border-gray-200 rounded-md"/>
                        euro
                    </div>
                </div>


                <PrimaryButton title={'Filter'} onClick={() => {
                    const filtersToAdd = [];
                    /*@ts-ignore*/
                    if (startDate.current.value && endDate.current.value)
                        filtersToAdd.push({
                            id: 'filterBookingDate',
                            operation: FilterOperation.BETWEEN,
                            columnId: 'bookingDate',
                            /*@ts-ignore*/
                            values: [startDate.current.value, endDate.current.value]
                        })
                    /*@ts-ignore*/
                    if (flowFilter.current.value && flowFilter.current.value !== 'any')
                        filtersToAdd.push({
                            id: 'filterFlow',
                            operation: FilterOperation.EQUALS,
                            columnId: 'flow',
                            /*@ts-ignore*/
                            values: [flowFilter.current.value]
                        })
                    /*@ts-ignore*/
                    if (amountComparatorType.current.value && amountComparisonAmount.current.value)
                        filtersToAdd.push({
                            id: 'filterAmount',
                            /*@ts-ignore*/
                            operation: amountComparatorType.current.value === 'gt' ? FilterOperation.GREATER_THAN : FilterOperation.LESS_THAN,
                            columnId: 'value',
                            /*@ts-ignore*/
                            values: [amountComparisonAmount.current.value * 100]
                        })


                    addFilters(filtersToAdd)

                    setIsFilterDrawerOpen(false)
                }}/>

            </NarrowDrawer>
            <Shell currentUser={currentUser} isLoading={isLoading}>
                <h1 className="weight-600 text-3xl mb-6 ">Transacties</h1>

                <div className="flex flex-col">
                    <div className="flex justify-between">
                        <div>
                            <div className="mr-auto max-w-7xl py-3 sm:flex sm:items-center sm:pr-6 ">
                                <h3 className="text-sm font-medium text-gray-500">
                                    Filters
                                </h3>

                                <div aria-hidden="true" className="hidden h-5 w-px bg-gray-300 sm:ml-4 sm:block"/>

                                <div className="mt-2 sm:ml-4 sm:mt-0">
                                    <div className="-m-1 flex flex-wrap items-center">
                                        {filters.map((activeFilter) => (
                                            <span
                                                className="m-1 inline-flex items-center rounded-full border border-gray-200 bg-white py-1.5 pl-3 pr-2 text-sm font-medium text-gray-900"
                                            >
                        <span>{renderFilter(activeFilter)}</span>
                        <button
                            type="button"
                            className="ml-1 inline-flex h-4 w-4 flex-shrink-0 rounded-full p-1 text-gray-400 hover:bg-gray-200 hover:text-gray-500"
                            onClick={() => removeFilter(activeFilter)}
                        >
                          <span className="sr-only">Remove filter for {activeFilter.label}</span>
                          <svg fill="none" stroke="currentColor" viewBox="0 0 8 8" className="h-2 w-2">
                            <path d="M1 1l6 6m0-6L1 7" strokeWidth="1.5" strokeLinecap="round"/>
                          </svg>
                        </button>
                      </span>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex items-center mr-4'>
                            <PrimaryButton title={'Filter toevoegen'}
                                           onClick={() => setIsFilterDrawerOpen(!isFilterDrawerOpen)} className=""/>
                        </div>
                    </div>
                    <TransactionsTable
                        transactions={transactions}
                        bankAccounts={bankAccounts}
                        onWrongCategoryClick={handleOnWrongCategoryClick}
                        sortingState={tableSortingState}
                        paginationState={tablePaginationState}
                        onQueryUpdate={(pagination, sorting) => onLoad(sorting)}
                    />
                </div>
            </Shell>
        </>

    )
}
