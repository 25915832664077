import {User} from "../api/accessControl/User.ts";
import {BackendApi} from "../api/api.ts";
import {HTTPCurrentUserRepository} from "../api/accessControl/HTTPCurrentUserRepository.ts";

export async function createUser(token: string): Promise<User> {
    const api = new BackendApi(token);
    const userRepository = new HTTPCurrentUserRepository(api);


    return await userRepository.getCurrentUser()
}

export async function processCurrentAuthState(firebaseUser: any, onSuccessfulAuthenticationCallback: (user: User) => void): Promise<User | null> {
    if (!firebaseUser)
        return null

    try {
        const FirebaseUserToken = await firebaseUser.getIdToken()
        const user = await createUser(FirebaseUserToken)
        onSuccessfulAuthenticationCallback(user)
        return user
    } catch (err) {
        alert('An error occurred' + err)
        return null
    }
}
