import {syntaxHighlight} from "./pages/baseTransactions/BaseTransactions.tsx";
import {BaseTransaction} from "../../../api/baseTransactions/BaseTransaction.ts";
import LoadingFrame from "../../../components/LoadingFrame.tsx";
import {useEffect, useState} from "react";

interface Props {
    transaction: BaseTransaction
    queryTransactionHistory: (transactionId: string) => Promise<Array<Object>>
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

interface Log {
    id: string,
    first_name: string,
    last_name: string,
    changed_on: string,
    old_category: string,
    new_category: string,

}

function getAgentName(historyItem: any) {
    if (historyItem?.first_name && historyItem?.last_name)
        return historyItem?.first_name + ' ' + historyItem?.last_name

    if (historyItem?.agent?.type === 'SYSTEM_PROCESSOR')
        return historyItem?.agent?.identifier + ' 🤖'

    return historyItem?.agent?.identifier + ' ' + historyItem?.agent?.type
}

export default function TransactionSpecs({transaction, queryTransactionHistory}: Props) {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [transactionHistory, setTransactionHistory] = useState<Array<Object>>([]);

    function load() {
        queryTransactionHistory(transaction.id).then(data => setTransactionHistory(data)).then(() => setIsLoading(false))
    }

    useEffect(() => {
        load()
    }, [])

    return (
        <div className="relative mt-6 flex-1 px-4 sm:px-6">
            {transaction && Object.entries(transaction).map((prop) => {
                return (
                    <>
                        <span className="text-sm mt-3 block">{prop[0]}</span>
                        <pre
                            className="w-full  p-4 bg-gray-100 rounded-md overflow-x-auto "
                            dangerouslySetInnerHTML={{__html: syntaxHighlight(JSON.stringify(prop[1], null, 2))}}>

                                                </pre>
                    </>

                )
            })}

            <LoadingFrame isLoading={isLoading}>
                <h2 className="text-lg font-bold mb-4 mt-8">Transaction history</h2>
                <ul role="list" className="space-y-6 border border-gray-200 rounded-md bg-white p-6">
                    {transactionHistory.map((log: Log, logIdx) => (
                        <li key={logIdx} className="relative flex gap-x-4">
                            <div
                                className={classNames(
                                    logIdx === transactionHistory.length - 1 ? 'h-6' : '-bottom-6',
                                    'absolute left-0 top-0 flex w-6 justify-center',
                                )}
                            >
                                <div className="w-px bg-gray-200"/>
                            </div>

                            <>
                                <div
                                    className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                                    <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300"/>
                                </div>
                                <p className="flex-auto py-0.5 text-sm  text-gray-500">
                                        <span
                                            className="font-medium text-gray-600">{getAgentName(log)}</span> changed the
                                    category from <strong className="text-black">{log.old_category}</strong> to <strong
                                    className="text-black">{log.new_category}</strong>
                                </p>
                                <time dateTime={log.changed_on}
                                      className="flex-none py-0.5 text-xs/5 text-gray-500">
                                    {new Date(log.changed_on).toLocaleString()}
                                </time>
                            </>

                        </li>
                    ))}

                    {transactionHistory?.length === 0 && <span>No transaction history</span>}
                </ul>
            </LoadingFrame>
        </div>
    )
}


