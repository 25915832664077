import {User} from "../../../../../api/accessControl/User.ts";
import {useEffect, useState} from "react";
import {CustomerBankAccount} from "../../../../../api/customerBankAccounts/CustomerBankAccount.ts";
import {BackendApi} from "../../../../../api/api.ts";
import {CustomerBankAccountRepository} from "../../../../../api/customerBankAccounts/CustomerBankAccountRepository.ts";
import BankAccountSelectionSelector from "../CashflowPage/BankAccountSelector.tsx";
import ShinyButton from "../CashflowPage/ShinyButton.tsx";
import DailyBalanceGraph from "./DailyBalanceGraph.tsx";
import Shell from "../../../../../components/specific/Shell.tsx";
import Popup from "../../../../../components/general/Popup.tsx";

function getDateStringFromTab(tabValue: number) {
    return new Date(tabValue, 0, 1).toISOString().split('T')[0]
}

export function BalancePage({currentUser}: { currentUser: User }) {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [boiGraph, setBoiGraph] = useState();
    const [selectedTabs, setSelectedTabs] = useState([new Date().getFullYear()]);
    const [bankAccounts, setBankAccounts] = useState<Array<CustomerBankAccount>>([]);

    const [isForecastingPopupOpen, setIsForecastingPopupOpen] = useState(false);
    const [selectedBankAccountId, setSelectedBankAccountId] = useState<string>();

    useEffect(() => {
        onLoad()
    }, []);

    const api = new BackendApi(currentUser.getToken())


    async function fetchBoiGraphData(bankAccountId: string | null = null) {
        const body = {
            business_id: currentUser.businesses[0],
            start_date: getDateStringFromTab(Math.min(...selectedTabs)),
            end_date: getDateStringFromTab(Math.max(...selectedTabs) + 1),
        }

        let finalBody;

        if (bankAccountId) {
            finalBody = {...body, bank_account_id: bankAccountId}
        } else {
            finalBody = body
        }

        const res = await api.get(
            'graph/business/get-daily-balance-with-forecast',
            finalBody
        )
        res.json().then(data => setBoiGraph(data)).then(() => setIsLoading(false))
    }


    async function onLoad() {
        const bankAccountsResponse = await new CustomerBankAccountRepository(api)
            .getMyBankAccounts(currentUser.businesses[0], true)

        setBankAccounts(bankAccountsResponse)
        fetchBoiGraphData(selectedBankAccountId)

    }

    function handleDropdownChange(bankAccount: CustomerBankAccount) {
        setSelectedBankAccountId(bankAccount?.id)
        fetchBoiGraphData(bankAccount?.id)
    }

    const thisYear = new Date().getFullYear()
    const years = [thisYear - 2, thisYear - 1, thisYear];

    useEffect(() => {
        fetchBoiGraphData(selectedBankAccountId)
    }, [selectedTabs]);


    return (
        <>
            <Popup
                open={isForecastingPopupOpen}
                title={'Zet forecasting aan en geef je bedrijf een boost'}
                onClose={() => setIsForecastingPopupOpen(false)}
                onConfirm={async () => {
                    await currentUser.flags.setFlag('IS_FORECASTING_ENABLED', true)
                    window.location.reload();
                }}
            >
                We hebben deze samengesteld met de nieuwste modellen en je historische bankgegevens. Het biedt een
                solide uitgangspunt, maar de toekomst kan verrassingen in petto hebben.
                <br/><br/>
                Wil je een meer nauwkeurige en op maat gemaakte voorspelling? Deel je plannen met ons, zoals nieuwe
                medewerkers aannemen, grote aankopen, belangrijke verkoopcontracten, aankomende financieringen.
                Vertel het gewoon aan Quinn, en wij verwerken het in de volgende updates. Zo maken we van deze
                basisprognose een strategische voorspelling die aansluit op jouw plannen. Heb je vragen? Neem gerust
                contact met ons op!
                <br/><br/>
                Kijk vooruit met PocketCFO forecasts!
            </Popup>
            <Shell currentUser={currentUser} isLoading={isLoading}>
                <div className="w-full flex items-center mb-8 justify-between">
                    <div className="flex items-center gap-x-6">
                        <h1 className="weight-600 text-3xl">Saldo</h1>


                        {(!currentUser.flags.getFlag('IS_FORECASTING_ENABLED') && currentUser.flags.getFlag('CAN_ENABLE_FORECASTING')) &&
                            <ShinyButton
                                onClick={() => setIsForecastingPopupOpen(true)}
                            >
                            <span className="inline-flex items-center gap-x-1">
                                Enable forecasting
                                <svg
                                    fill="none"
                                    height="16"
                                    viewBox="0 0 24 24"
                                    width="16"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                <path
                                    d="M10.75 8.75L14.25 12L10.75 15.25"
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.5"
                                />
                            </svg>

                            </span>

                            </ShinyButton>}


                    </div>
                    <BankAccountSelectionSelector defaultValue={'All'} bankAccounts={bankAccounts}
                                                  onUpdateFunc={handleDropdownChange}/>
                    <div className="">
                        <nav aria-label="Tabs" className="flex space-x-4">
                            {years.map((tab) => (
                                <button
                                    key={tab}
                                    onClick={
                                        () => {
                                            if (selectedTabs.includes(tab)) {
                                                if (selectedTabs.length === 1)
                                                    return
                                                setSelectedTabs((prev) => prev.filter((year) => year !== tab))
                                            } else {
                                                setSelectedTabs((prev) => [...prev, tab])
                                            }
                                        }
                                    }
                                    className={(selectedTabs.includes(tab) ? 'bg-gray-100 text-gray-900' : 'text-gray-500 hover:text-gray-700') + 'rounded-md px-3 py-2 text-sm font-medium rounded-md'}
                                >
                                    {tab}
                                </button>
                            ))}
                        </nav>
                    </div>

                </div>
                <DailyBalanceGraph data={boiGraph} className={"h-[90vh]"}></DailyBalanceGraph>

            </Shell>
        </>
    )
}
