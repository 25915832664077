import Shell from "../../../../../components/specific/Shell.tsx";
import {BackendApi} from "../../../../../api/api.ts";
import {ApiResult, BaseTransactionsRepository} from "../../../../../api/baseTransactions/BaseTransactionsRepository.ts";
import {useEffect, useState} from "react";
import {BaseTransaction} from "../../../../../api/baseTransactions/BaseTransaction.ts";
import {Dialog, DialogPanel, DialogTitle} from "@headlessui/react";
import {ArrowTopRightOnSquareIcon, XMarkIcon} from "@heroicons/react/24/outline";
import Dropdown from "../../../../../components/general/Dropdown.tsx";
import {CustomerBankAccountRepository} from "../../../../../api/customerBankAccounts/CustomerBankAccountRepository.ts";
import {CustomerBankAccount} from "../../../../../api/customerBankAccounts/CustomerBankAccount.ts";
import {CategoriesListBox} from "./CategoriesListBox.tsx";
import {TransactionRow} from "./TransactionRow.tsx";
import Checkbox from "../../../../../components/general/Checkbox.tsx";
import {User} from "../../../../../api/accessControl/User.ts";


import {Link} from "react-router-dom";
import LoadingFrame from "../../../../../components/LoadingFrame.tsx";
import TransactionSpecs from "../../TransactionSpecs.tsx";

export function syntaxHighlight(json) {
    if (!json) return ""; //no JSON from response

    json = json
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;");
    return json.replace(
        /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
        function (match) {
            let cls = "number";
            if (/^"/.test(match)) {
                if (/:$/.test(match)) {
                    cls = "text-blue-600 font-bold";
                } else {
                    cls = "text-green-600";
                }
            } else if (/true|false/.test(match)) {
                cls = "text-blue-300";
            } else if (/null/.test(match)) {
                cls = "text-gray-300";
            }
            return '<span class="' + cls + '">' + match + "</span>";
        }
    );
}

interface Section {
    iban: string,
    name: string,
    transactions: Array<BaseTransaction>
}

interface Filters {
    onlyShowUncategorized: boolean,
}

function SectionStatistics({userToken, iban, name, bank_account_id}: {
    userToken: string,
    iban: string,
    name: string,
    bank_account_id: string
}) {
    const [valueSSS, setValue] = useState('loading...');

    async function onLoad() {
        const api = new BackendApi(userToken);
        const res = await api.get('catestial/base-transactions/count', {
            relation_iban: iban,
            relation_name: name,
            bank_account_id: bank_account_id
        })
        const resJson = await res.json()

        setValue(await resJson['result']);
        console.log(await resJson);
    }

    useEffect(() => {
        onLoad()

    }, [])

    return (
        <span>{valueSSS}</span>
    )
}

function getAggregatedTransactions(baseTransactions: ApiResult<BaseTransaction[]>): Array<Section> {
    const newArray = [];

    for (var i = 0; i < baseTransactions.results.length; i++) {

        const idx = newArray.findIndex(x => x.iban + x.name === baseTransactions.results[i].iban + baseTransactions.results[i].relationName);

        if (idx < 0) {
            newArray.push({
                iban: baseTransactions.results[i].iban,
                name: baseTransactions.results[i].relationName,
                transactions: [baseTransactions.results[i]]
            });
        } else {
            newArray[idx] = {
                ...newArray[idx], transactions: newArray[idx].transactions.concat(baseTransactions.results[i])
            };
        }
    }

    return newArray;
}

export default function BaseTransactions({currentUser}: { currentUser: User }) {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoadingInternally, setIsLoadingInternally] = useState<boolean>(true);

    const [currentPage, setCurrentPage] = useState(1)
    const [filters, setFilters] = useState<Filters>({
        onlyShowUncategorized: false,
    });
    const [baseTransactions, setBaseTransactions] = useState<ApiResult<BaseTransaction[]>>()
    const [selectedBankAccount, setSelectedBankAccount] = useState<CustomerBankAccount>();
    const [bankAccounts, setBankAccounts] = useState<Array<CustomerBankAccount>>();

    const [open, setOpen] = useState<BaseTransaction | null>(null)

    async function onLoad() {
        const backendApi = new BackendApi(currentUser.getToken())


        const customerBankAccountRepository = new CustomerBankAccountRepository(backendApi)
        const bankAccounts_ = await customerBankAccountRepository.getAllBankAccounts();
        setSelectedBankAccount(await bankAccounts_[0]);
        setBankAccounts(bankAccounts_);
    }

    useEffect(() => {
        onLoad()
    }, []);

    const api = new BackendApi(currentUser.getToken());

    async function loadTransactions(page: number) {
        const baseTransactionsRepository = new BaseTransactionsRepository(api);
        const baseTransactions = await baseTransactionsRepository.getCustomerBankAccountTransactions(selectedBankAccount.id, page, filters.onlyShowUncategorized);

        setBaseTransactions(baseTransactions)
    }

    useEffect(() => {
        setIsLoadingInternally(true)
        setBaseTransactions(new ApiResult(0, 0, 0, []));

        if (selectedBankAccount === undefined)
            return

        loadTransactions(currentPage).then(() => {
            setIsLoading(false)
            setIsLoadingInternally(false)
        })
    }, [selectedBankAccount, currentPage, filters]);


    return (
        <>
            <Dialog open={!!open} onClose={() => setOpen(null)} className="relative z-10">
                <div className="fixed inset-0"/>

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                            <DialogPanel
                                transition
                                className="pointer-events-auto w-screen max-w-4xl transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
                            >
                                <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                    <div className="px-4 sm:px-6">
                                        <div className="flex items-start justify-between">
                                            <DialogTitle
                                                className="text-base font-semibold leading-6 text-gray-900">{open?.iban}</DialogTitle>
                                            <div className="ml-3 flex h-7 items-center">
                                                <button
                                                    type="button"
                                                    onClick={() => setOpen(null)}
                                                    className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                                >
                                                    <span className="absolute -inset-2.5"/>
                                                    <span className="sr-only">Close panel</span>
                                                    <XMarkIcon aria-hidden="true" className="h-6 w-6"/>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                        <div className="flex justify-between">
                                            <span>Raw Transaction Object:</span>
                                            <Link
                                                type="button"
                                                to={'/base-transactions/' + open?.id}
                                                className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                            >
                                                Open
                                                <ArrowTopRightOnSquareIcon aria-hidden="true"
                                                                           className="-mr-0.5 h-5 w-5"/>
                                            </Link>
                                        </div>

                                        <TransactionSpecs transaction={open}
                                                          queryTransactionHistory={async (transactionId) => {
                                                              const result = await api.get('transactions/get-transaction-history', {transaction_id: transactionId})
                                                              return await result.json()
                                                          }}></TransactionSpecs>


                                    </div>
                                </div>
                            </DialogPanel>
                        </div>
                    </div>
                </div>
            </Dialog>
            <Shell currentUser={currentUser} isLoading={isLoading}>
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-base font-semibold leading-6 text-gray-900">Base Transactions</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                These human categorised transactions are used to test the efficacy of different
                                categorisation algorithms
                            </p>
                        </div>
                        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                        </div>
                    </div>
                    <div className="mt-8 flow-root">
                        <div className="px-6 py-3 bg-gray-100 my-4 rounded-md flex items-center justify-between w-full">
                            <div className="w-auto flex items-center gap-2">
                                <span className="text-sm">Bank Account</span>
                                <Dropdown
                                    selected={{
                                        value: `${selectedBankAccount?.ownerName} ${selectedBankAccount?.iban} ${selectedBankAccount?.currency}`,
                                        tailwindColor: null
                                    }}
                                    options={bankAccounts?.map((bankAccount: CustomerBankAccount) => {
                                        return {
                                            value: `${bankAccount?.ownerName} ${bankAccount?.iban} ${bankAccount?.currency}`,
                                            tailwindColor: null
                                        }
                                    })}
                                    onUpdateFunc={async (value: string) => {
                                        setIsLoading(true);
                                        for (const bankAccount of bankAccounts) {
                                            if (`${bankAccount?.ownerName} ${bankAccount?.iban} ${bankAccount?.currency}` === value) {
                                                setSelectedBankAccount(bankAccount);
                                            }


                                        }
                                    }}/>
                            </div>
                            <div>
                                <Checkbox value={filters.onlyShowUncategorized}
                                          onUpdateFunc={(newValue: boolean) => {
                                              console.log(newValue)
                                              setFilters((oldFilters: Filters) => {
                                                  return {...oldFilters, onlyShowUncategorized: newValue}
                                              })
                                          }}
                                          label={"Only show uncategorized"}
                                />
                            </div>
                            <div className="flex gap-3 items-center text-sm">
                                <button
                                    type="button"
                                    className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                    onClick={() =>
                                        setCurrentPage((old: number) => {
                                            if (old - 1 < 1)
                                                return 1

                                            return old - 1
                                        })
                                    }
                                >
                                    Prev
                                </button>
                                <span>
                                    Page {baseTransactions?.currentPage} of {baseTransactions?.totalPages}
                                </span>
                                <button
                                    type="button"
                                    className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                    onClick={() =>
                                        setCurrentPage((old: number) => {
                                            if (old + 1 > baseTransactions.totalPages)
                                                return old

                                            return old + 1
                                        })
                                    }
                                >
                                    Next
                                </button>
                                <div
                                    className="text-sm">{baseTransactions && <>{baseTransactions.totalResults} Transactions</>}</div>
                            </div>

                        </div>
                        <LoadingFrame isLoading={isLoadingInternally}>
                            <div className="inline-block min-w-full py-2 align-middle relative">

                                <table className="min-w-full border-separate border-spacing-0 border-collapse">
                                    <thead>
                                    <tr>
                                        <th
                                            scope="col"
                                            className="sticky top-0 z-10 border-b pl-0 ml-0 border-gray-300 bg-white bg-opacity-75  text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                                        >
                                            IBAN
                                        </th>
                                        <th
                                            scope="col"
                                            className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                                        >
                                            CounterParty
                                        </th>
                                        <th
                                            scope="col"
                                            className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                                        >
                                            Description
                                        </th>
                                        <th
                                            scope="col"
                                            className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                                        >
                                            Date
                                        </th>
                                        <th
                                            scope="col"
                                            className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                                        >
                                            Amount
                                        </th>
                                        <th
                                            scope="col"
                                            className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                                        >
                                            Category
                                        </th>
                                    </tr>
                                    </thead>

                                    {baseTransactions && getAggregatedTransactions(baseTransactions).map((section: Section) => (<>
                                        <tbody className="bg-gray-100 border-b-8 border-white">
                                        <tr>
                                            <td colSpan={6} className="py-4 pt-8">
                                                <div
                                                    className="flex justify-between items-center">
                                                    <div>
                                                    <span
                                                        className="mb-4 block font-bold font-lg">{section.iban} - {section.name} ({section.transactions.length} transactions)</span>

                                                        {/*<SectionStatistics userToken={userToken} iban={section.iban}*/}
                                                        {/*                   name={section.name}*/}
                                                        {/*                   bank_account_id={selectedBankAccount.id}/>*/}
                                                    </div>

                                                    <div className="mb-4 block">
                                                        <CategoriesListBox defaultCat={section.transactions[0].category}
                                                                           onChangeFunc={async (newCategory: string) => {
                                                                               const transactionsToUpdate = section.transactions.map((oldTransaction) => {
                                                                                   return oldTransaction.categorize(newCategory)
                                                                               })

                                                                               const api = new BackendApi(currentUser.getToken());
                                                                               const baseTransactionsRepository = new BaseTransactionsRepository(api);

                                                                               await baseTransactionsRepository.bulkCategorize(
                                                                                   transactionsToUpdate.map((transaction) => transaction.id),
                                                                                   newCategory
                                                                               )

                                                                               for (const transaction of transactionsToUpdate)
                                                                                   setBaseTransactions((old: ApiResult<BaseTransaction[]>): ApiResult<BaseTransaction[]> => {
                                                                                       const newResults = old.results.map((oldTrans: BaseTransaction) => {
                                                                                           if (transactionsToUpdate.map((tr) => tr.id).includes(oldTrans.id)) {
                                                                                               console.log('updating transaction')
                                                                                               return transactionsToUpdate.filter((tr) => tr.id === oldTrans.id)[0]
                                                                                           }

                                                                                           return oldTrans
                                                                                       })

                                                                                       return new ApiResult<BaseTransaction[]>(old.currentPage, old.totalPages, old.totalResults, newResults)

                                                                                   })
                                                                           }}
                                                                           flow='both'
                                                        />
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>

                                        {section.transactions && section.transactions.sort().map((row: BaseTransaction) => (
                                            <TransactionRow transaction={row}
                                                            onInfoClick={(transaction) => setOpen(transaction)}
                                                            onChangeFunc={(newCategory, oldTransaction) => {
                                                                const updatedTransaction = oldTransaction.categorize(newCategory)
                                                                const api = new BackendApi(currentUser.getToken());
                                                                const baseTransactionsRepository = new BaseTransactionsRepository(api);
                                                                baseTransactionsRepository.save(updatedTransaction)
                                                                setBaseTransactions((old: ApiResult<BaseTransaction[]>): ApiResult<BaseTransaction[]> => {
                                                                    const newResults = old.results.map((oldTrans: BaseTransaction) => {
                                                                        if (oldTrans.id === updatedTransaction.id)
                                                                            return updatedTransaction
                                                                        return oldTrans
                                                                    })

                                                                    return new ApiResult<BaseTransaction[]>(old.currentPage, old.totalPages, old.totalResults, newResults)
                                                                })

                                                            }
                                                            }

                                                            readOnly={false}/>))}
                                        </tbody>
                                        <tr className='h-8 rounded-md'></tr>
                                    </>))}
                                </table>
                                {isLoading && <div
                                    className={"absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center h-[90vh]"}>
                                    <img src="/loader-slow.gif" className={"h-16 w-16"} alt=""/></div>}
                                {(baseTransactions?.results?.length === 0 && !isLoading) &&
                                    <div className="w-full text-center my-8 text-gray-400">No transactions to
                                        show</div>}
                            </div>
                        </LoadingFrame>

                    </div>
                </div>
            </Shell>

        </>
    )
}
