import {User} from "../api/accessControl/User.ts";
import {Route} from "react-router-dom";
import React from "react";

export interface RouteCondition {
    conditionEval: ((user: User | null) => Promise<boolean>) | ((user: User | null) => boolean)
    name: string
}

export class TFICRouter {
    private routes: Array<{ path: string, conditionName: string, component: any }> = [];
    private conditions: Array<RouteCondition> = [];

    constructor(private readonly user: User | null) {

    }

    public registerCondition(condition: RouteCondition) {
        this.conditions.push(condition)
    }

    public registerRoute(path: string, conditionName: string, component: (user: User) => any) {
        this.routes.push({path: path, conditionName: conditionName, component: component})

    }

    public async resolve() {

        let bootedRoutes = [];

        for (const condition of this.conditions) {

            let conditionEval = condition.conditionEval(this.user)

            if (conditionEval instanceof Promise)
                conditionEval = await conditionEval

            if (!conditionEval)
                continue

            const routes = this.routes.filter((route) => route.conditionName === condition.name)
            bootedRoutes = [this.bootRoutes(routes), ...bootedRoutes]
        }

        return bootedRoutes;
    }

    private bootRoutes(routes: Array<{ path: string, conditionName: string, component: any }>): any {
        const result = [];

        for (const route of routes) {
            result.push(<Route key={route.path} path={route.path} element={route.component(this.user)}/>)
        }

        return result;
    }
}
