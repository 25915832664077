import {createTable, TableHeaderSpec} from "../../../../../components/table/CreateTable.tsx";
import React from "react";
import {Business} from "./BusinessesOverviewPage.tsx";
import {SecondaryButton} from "../../../../../components/general/SecondaryButton.tsx";

interface Props {
    data: Array<Business>,
    onOpenClick: (business: Business) => void
}

export function BusinessTable({data, onOpenClick}: Props) {
    const tableSpec: TableHeaderSpec<Business> = {

        columns: [

            {
                name: 'Name',
                field: 'name',
            },
            {
                name: 'Legal Entity Type',
                field: 'legal_entity_type'
            },
            {
                name: 'Registration Number',
                field: 'registration_number'
            },
            {
                name: 'FTEs',
                field: 'fte_amount',
            },
            {
                name: 'Industry',
                field: 'industry',
            },
            {
                name: '',
                field: '',
                customRender: (row: Business) => (
                    <SecondaryButton onClick={() => onOpenClick(row)} title={'See details'}/>
                )
            },

        ]
    }

    const TableComponent = createTable<Business>(data, tableSpec)

    return (
        <>
            {TableComponent()}
        </>
    )
}
