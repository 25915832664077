import {User} from "../../../../api/accessControl/User.ts";
import {useEffect, useState} from "react";
import {BackendApi} from "../../../../api/api.ts";
import TaxesHistory from "../TaxesHistory.tsx";
import Shell from "../../../../components/specific/Shell.tsx";

export default function TaxesPage({currentUser}: { currentUser: User }) {
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [taxData, setTaxData] = useState<any>([]);

    const api = new BackendApi(currentUser.getToken())

    async function onLoad() {
        api.get('business/tax/history', {business_id: currentUser.businesses[0]}).then(data => data.json()).then(data => setTaxData(data)).then(() => setIsLoading(false))
        console.log(taxData)
    }

    useEffect(() => {
        onLoad()
    }, []);

    if (!taxData) return;

    return (
        <Shell currentUser={currentUser} isLoading={isLoading}>
            <h1 className="weight-600 text-3xl mb-6 ">Belastinggeschiedenis</h1>
            <div className="flex gap-3 items-center">
                <TaxesHistory history={taxData}/>
            </div>
        </Shell>
    )
}
