import {User} from "../../api/accessControl/User.ts";
import {useEffect, useState} from "react";
import {BackendApi} from "../../api/api.ts";
import LoadingFrame from "../../components/LoadingFrame.tsx";
import {Header} from "../../components/general/Header.tsx";

function getBanksByCountryCode(api: BackendApi) {
    return async (countryCode: string) => {
        const responseRaw = await api.get('get-banks-by-country-code', {
            country_code: countryCode
        })
        return await responseRaw.json();
    }
}

export function ConnectABankPage({currentUser}: { currentUser: User }) {
    const backendApi = new BackendApi(currentUser.getToken())

    const [isLoading, setIsLoading] = useState<boolean>(false);

    return (
        <LoadingFrame isLoading={isLoading}>
            <div>
                <Header/>
                <div className="bg-white">
                    <div className="mx-auto max-w-1xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900 ">{t('title')}</h2>
                        <p className="mt-2 text-sm leading-6 text-gray-500 mb-8">
                            {t('subtitle')}
                        </p>
                        <Tabs getBanks={getBanksByCountryCode(backendApi)} onBankSelected={(bank: SelectedBank) => {
                            setIsLoading(true);
                            backendApi.post('initiate-bank-connection', {
                                bank_id: bank.id,
                                bank_max_days_history: bank.max_historical_days
                            }).then((data) => (data.json())).then((data) => {
                                window.location.replace(data.redirect_url)
                            })
                        }}/>
                    </div>
                </div>
            </div>
        </LoadingFrame>

    )
}


const lang = 'en';

const translations = {
    en: {
        title: "Select your bank",
        subtitle: "Please, select the bank that you use for your business, not your personal bank account"
    },
    nl: {
        title: "Selecteer jouw bank",
        subtitle: "Selecteer de bank die je voor je bedrijf gebruikt, niet je persoonlijke bankrekening"
    },
}

function t(id) {
    return translations[lang][id];
}


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

interface SelectedBank {
    id: string,
    max_historical_days: number
}

function Tabs({getBanks, onBankSelected}: { getBanks: any, onBankSelected: (bank: SelectedBank) => void }) {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const tabs = [
        {name: '🇳🇱 Netherlands', countryCode: 'nl'},
        {name: '🇩🇪 Germany', countryCode: 'de'},
        {name: '🇧🇪 Belgium', countryCode: 'be'},
        {name: '🇬🇧 United Kingdom', countryCode: 'gb'},
    ];

    const [currentTab, setCurrentTab] = useState(tabs[0]);

    const [currentSelection, setCurrentSelection] = useState([]);
    const [allBanks, setAllBanks] = useState([]);
    const [search, setSearch] = useState("");

    useEffect(() => {
        setIsLoading(true)
        setCurrentSelection([])
        setAllBanks([])

        console.log('updating banks for new tabs', currentTab)

        getBanks(currentTab.countryCode)
            .then(data => setAllBanks((prev) => {
                setCurrentSelection(data)
                return data
            }))
            .then(() => setIsLoading(false))
    }, [currentTab]);

    useEffect(() => {
        getBanks(currentTab.countryCode)
            .then(data => setAllBanks(data))
            .then(() => setIsLoading(false))
    }, []);

    useEffect(() => {
        if (isLoading)
            return
        if (search)
            setCurrentSelection(allBanks.filter((val => val.name.toLowerCase().startsWith(search.toLowerCase()))))
        else
            setCurrentSelection(allBanks)
    }, [search]);


    return (
        <div>
            <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">
                    Select a tab
                </label>
                {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                <select
                    id="tabs"
                    name="tabs"
                    className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    defaultValue={tabs.find((tab) => currentTab.name === tab.name).name}
                    onChange={(e) => setCurrentTab(tabs.find((tab) => e.target.value === tab.name))}
                >
                    {tabs.map((tab) => (
                        <option key={tab.name}>{tab.name}</option>
                    ))}
                </select>
            </div>
            <div className="hidden sm:block">
                <div className="border-b border-gray-200 flex justify-between items-center">
                    <nav className="-mb-px flex space-x-8" aria-label="Tabs">


                        {tabs.map((tab) => (
                            <button
                                key={tab.name}
                                onClick={(e) => {
                                    setCurrentTab(tab)
                                }}
                                className={classNames(
                                    currentTab.name === tab.name
                                        ? 'border-indigo-500 text-indigo-600'
                                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                    'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium'
                                )}
                            >
                                {tab.name}
                            </button>
                        ))}
                    </nav>
                    <div className="md:flex h-8 hidden items-center gap-2">


                        <input type="text" onChange={(e) => (setSearch(e.target.value))}
                               className=" px-2 block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-200 placeholder:text-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"/>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                             className="size-6">
                            <path fillRule="evenodd"
                                  d="M3.792 2.938A49.069 49.069 0 0 1 12 2.25c2.797 0 5.54.236 8.209.688a1.857 1.857 0 0 1 1.541 1.836v1.044a3 3 0 0 1-.879 2.121l-6.182 6.182a1.5 1.5 0 0 0-.439 1.061v2.927a3 3 0 0 1-1.658 2.684l-1.757.878A.75.75 0 0 1 9.75 21v-5.818a1.5 1.5 0 0 0-.44-1.06L3.13 7.938a3 3 0 0 1-.879-2.121V4.774c0-.897.64-1.683 1.542-1.836Z"
                                  clipRule="evenodd"/>
                        </svg>

                    </div>
                </div>
            </div>
            <div className="flex h-8 md:hidden mt-8 items-center gap-2">


                <input type="text" onChange={(e) => (setSearch(e.target.value))}
                       className=" px-2 block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-200 placeholder:text-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"/>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
                    <path fillRule="evenodd"
                          d="M3.792 2.938A49.069 49.069 0 0 1 12 2.25c2.797 0 5.54.236 8.209.688a1.857 1.857 0 0 1 1.541 1.836v1.044a3 3 0 0 1-.879 2.121l-6.182 6.182a1.5 1.5 0 0 0-.439 1.061v2.927a3 3 0 0 1-1.658 2.684l-1.757.878A.75.75 0 0 1 9.75 21v-5.818a1.5 1.5 0 0 0-.44-1.06L3.13 7.938a3 3 0 0 1-.879-2.121V4.774c0-.897.64-1.683 1.542-1.836Z"
                          clipRule="evenodd"/>
                </svg>

            </div>
            <LoadingFrame isLoading={isLoading}>
                <BankList banks={currentSelection} onClickFunc={(bank) => {
                    onBankSelected({
                        id: bank.id,
                        max_historical_days: bank.transaction_total_days
                    })
                }}/>
            </LoadingFrame>
        </div>
    )
}

function BankList({banks, onClickFunc}) {
    return (
        <div className="flex flex-col md:flex-row flex-wrap gap-2 md:gap-6 flex-1 mt-4">
            {banks.map((product) => (
                <button key={product.id}
                        onClick={() => {
                            onClickFunc(product)
                        }}
                        className="flex items-center md:block no-underline bg-white px-4 py-2 rounded-xl border-2 border-gray-100 hover:border-blue-200 cursor-pointer">
                    <img
                        src={product.logo}
                        alt={product.name}
                        className="w-8 m-2 md:w-32 md:m-8"
                    />
                    <h3 className="text-sm text-gray-400 text-right md:text-center md:max-w-24 mx-auto md:self-stretch md:pb-2">
                        {product.name}
                    </h3>
                </button>
            ))}
        </div>
    )
}

