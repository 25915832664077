import {
    createTable,
    PaginationState,
    RenderingPresetType,
    SortingState,
    TableHeaderSpec
} from "../../../../components/table/CreateTable.tsx";
import {BaseTransaction} from "../../../../api/baseTransactions/BaseTransaction.ts";
import {InformationCircleIcon} from "@heroicons/react/24/outline/index";

interface Props {
    data: Array<BaseTransaction>,
    onQueryUpdate: (pagination: PaginationState<BaseTransaction>, sorting: SortingState) => void,
    totalRecords: number,
    paginationState: [
        paginationState: PaginationState<BaseTransaction>,
        setPaginationState: (paginationState: any) => void
    ],
    sortingState: [
        sortingState: SortingState,
        setSortingState: (sortingState: any) => void
    ],
}

export function TransactionsAdminTable({data, onQueryUpdate, totalRecords, paginationState, sortingState}: Props) {
    const pageSize = 50

    const tableSpec: TableHeaderSpec<BaseTransaction> = {
        title: 'Transactions',
        columns: [
            {
                name: '',
                field: '',
                customRender: (row: BaseTransaction) => (
                    <span
                        onClick={() => window.open("/base-transactions/" + row.id, "Transaction")}><InformationCircleIcon
                        className="w-6 h-6 cursor-pointer"/></span>
                )
            },
            {
                name: 'Relation IBAN',
                field: 'iban'
            },
            {
                name: 'Relation Name',
                field: 'relationName'
            },
            {
                name: 'Description',
                field: 'description'
            },
            {
                name: 'Booking Date',
                field: 'date'
            },
            {
                name: 'Amount',
                field: 'value',
                renderingPreset: RenderingPresetType.MoneyColorAid
            },
            {
                name: 'Category',
                field: 'category',
                customRender: (row: BaseTransaction) => (
                    <span
                        className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1  font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
                            <svg viewBox="0 0 6 6" aria-hidden="true"
                                 className={(row.flow === 'inflow') ? 'fill-green-500 h-1.5 w-1.5' : 'fill-red-500 h-1.5 w-1.5'}>
                                <circle r={3} cx={3} cy={3}/>
                            </svg>
                        {row.category}
                    </span>
                )
            },
        ],
        pagination: {
            paginationState: paginationState,
            defaultPageSize: pageSize,
            totalPages: Math.ceil(totalRecords / pageSize),
        },
        sorting: {
            sortingState: sortingState,
        },
        onQueryUpdate: onQueryUpdate,
    }

    const TableComponent = createTable<BaseTransaction>(data, tableSpec)

    return (
        <>
            <TableComponent/>
        </>
    )
}
