import Shell from "../../../components/specific/Shell.tsx";
import {BackendApi} from "../../../api/api.ts";
import {signOut} from "firebase/auth";
import {auth} from "../../../firebaseConfig.ts";
import {User} from "../../../api/accessControl/User.ts";
import {RoleEnumToString} from "../../../api/accessControl/Role.ts";
import {useQuery} from "@tanstack/react-query";

export default function MyProfile({currentUser}: { currentUser: User }) {

    const backendApi = new BackendApi(currentUser.getToken())

    const {isPending: isLoading, isError, data: business, error} = useQuery({
        queryKey: ['business'],
        queryFn: async () => {
            const response = await backendApi.get('business/my-business')
            return await response.json()
        }
    })

    return (
        <>
            <Shell currentUser={currentUser} isLoading={isLoading}>
                <div className="relative mt-6 flex-1 px-4 sm:px-6">

                    <main className="px-4 sm:px-6 lg:flex-auto lg:px-0 ">
                        <div className="mx-auto max-w-2xl   lg:mx-0 lg:max-w-none">
                            <div>
                                <h1 className="weight-600 text-3xl mb-4">Hallo, {currentUser.getFullName()}</h1>
                                <p className="mt-1 text-sm leading-6 text-gray-500">
                                    Basisinformatie over je account.
                                </p>

                                <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                                    <div className="pt-6 sm:flex">
                                        <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Volledige
                                            naam
                                        </dt>
                                        <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                            <div className="text-gray-900">{currentUser.getFullName()}</div>
                                            {/*<button type="button"*/}
                                            {/*        className="font-semibold text-indigo-600 hover:text-indigo-500">*/}
                                            {/*    Update*/}
                                            {/*</button>*/}
                                        </dd>
                                    </div>
                                    <div className="pt-6 sm:flex">
                                        <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">E-mailadres</dt>
                                        <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                            <div className="text-gray-900">{currentUser.email}</div>
                                            {/*<button type="button"*/}
                                            {/*        className="font-semibold text-indigo-600 hover:text-indigo-500">*/}
                                            {/*    Update*/}
                                            {/*</button>*/}
                                        </dd>
                                    </div>
                                    <div className="pt-6 sm:flex">
                                        <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Accounttype</dt>
                                        <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                            <div className="text-gray-900">{RoleEnumToString(currentUser.role)}</div>
                                            {/*<button type="button"*/}
                                            {/*        className="font-semibold text-indigo-600 hover:text-indigo-500">*/}
                                            {/*    Update*/}
                                            {/*</button>*/}
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                            <div className="mt-16">
                                <h2 className="weight-600 text-2xl mb-4">{business?.name}</h2>
                                <p className="mt-1 text-sm leading-6 text-gray-500">
                                    Basisinformatie over je bedrijf.
                                </p>

                                <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                                    <div className="pt-6 sm:flex">
                                        <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                                            Aantal FTE's
                                        </dt>
                                        <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                            <div className="text-gray-900">{business?.fte_amount}</div>
                                        </dd>
                                    </div>
                                    <div className="pt-6 sm:flex">
                                        <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                                            Branche
                                        </dt>
                                        <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                            <div className="text-gray-900">{business?.industry}</div>
                                        </dd>
                                    </div>
                                    <div className="pt-6 sm:flex">
                                        <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                                            Entiteit
                                        </dt>
                                        <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                            <div className="text-gray-900">{business?.legal_entity_type}</div>
                                        </dd>
                                    </div>
                                    <div className="pt-6 sm:flex">
                                        <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                                            PocketCFO-analyse startdatum
                                        </dt>
                                        <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                                            <div className="text-gray-900">{
                                                business?.transaction_history_cutoff_date?.cutoff_date &&
                                                new Date(business?.transaction_history_cutoff_date?.cutoff_date).toDateString()
                                            }</div>
                                        </dd>
                                    </div>

                                </dl>
                            </div>
                        </div>
                    </main>
                    <button className="text-base bg-primary text-white px-4 py-2 rounded-md mt-12"
                            onClick={async () => {
                                await signOut(auth).then(() => {
                                    window.location.replace('/')
                                })

                            }}>Uitloggen
                    </button>
                </div>
            </Shell>
        </>
    )
}
