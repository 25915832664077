import {User} from "../../api/accessControl/User.ts";
import LoadingFrame from "../../components/LoadingFrame.tsx";
import {Header} from "../../components/general/Header.tsx";
import {useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {BackendApi} from "../../api/api.ts";

export function ReconnectBankSuccessPage({currentUser}: { currentUser: User }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isError, setIsError] = useState<boolean>(false);

    if (!searchParams.get('ref')) {
        return 'Something went wrong. Please contact us via hello@pocketcfo.io | CODE: ERR53489';
    }

    async function onLoad() {
        const api = new BackendApi(currentUser.getToken())

        try {
            await api.OpenBanking__reconnectBankAccount({
                ref_id: searchParams.get('ref')
            })
        } catch (e) {
            setIsError(true)
            return
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        onLoad()
    }, []);

    if (isError) {
        return 'Something went wrong. Please contact us via hello@pocketcfo.io | CODE: ERR53489';
    }

    return (
        <div>
            <Header/>
            <main className="bg-white py-24 sm:py-32">
                <LoadingFrame isLoading={isLoading}>
                    <div className="mx-auto max-w-7xl px-6 lg:px-8">


                        <div className="mx-auto max-w-2xl text-center">

                            <div className="flex items-center flex-col my-8">
                                <div role="status">
                                    <div className="flex-shrink-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             stroke-width="1.5"
                                             stroke="currentColor" className="size-24 text-green-400">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                  d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Connectie
                                succesvol!</p>
                            <p className="mt-6 text-lg leading-8 text-gray-600">Goed nieuws! We hebben je bankrekening
                                weer gekoppeld aan PocketCFO. Je kan nu terug naar het dashboard.</p>
                            <div className="mt-8">
                                <div className="bg-gray-50 rounded-3xl mx-auto w-xl">
                                    <div className="px-4 py-5 sm:p-6">
                                        <div className="flex items-center justify-between">
                                            <div
                                                className="mt-5 sm:mr-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">

                                                <h3 className="text-2xl font-semibold leading-2 text-gray-900">🤖</h3>
                                            </div>
                                            <div>
                                                <div className=" max-w-xl text-left text-md text-gray-500"><p>Op de
                                                    achtergrond zullen we een import uitvoeren om ervoor te zorgen dat
                                                    de gegevens van de vorige connectie goed worden gekoppeld aan de
                                                    nieuwe gegevens.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="flex justify-center w-full mt-16">
                                <a href="/"
                                   className="inline-flex items-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary">
                                    Naar PocketCFO &rarr;
                                </a>
                            </div>
                        </div>
                    </div>
                </LoadingFrame>
            </main>
        </div>
    )
}
