import {BackendApi} from "../../api/api.ts";


export class AdminBusinessFlagsService {
    private api: BackendApi;

    constructor(api: BackendApi) {
        this.api = api
    }

    public async getAllFlags(businessId: string): Promise<Record<string, boolean>> {
        const response = await this.api.get('admin/business/flags', {
            business_id: businessId,
        })
        return await response.json()
    }

    public async setFlag(businessId: string, name: string, value: boolean) {
        await this.api.post('admin/business/update-flag', {
            business_id: businessId,
            name: name,
            value: value
        })
    }
}
