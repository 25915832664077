import {BackendApi} from "../../api/api.ts";

export class UserBusinessFlagsService {
    constructor(
        private readonly flags: Record<string, boolean>,
        private readonly api: BackendApi
    ) {
    }

    public getFlag(key: string): boolean {
        if (!this.flags.hasOwnProperty(key))
            throw new Error(`Flag ${key} does not exist`)
        return this.flags[key]
    }

    public async setFlag(key: string, value: boolean) {
        await this.api.post('me/update-flag', {
            name: key,
            value: value
        })

        this.flags[key] = value
    }
}
