export interface Step {
    id: number,
    name: string,
    desc: string,
    onClick: (Step) => void,
    current: boolean // 1=complete, 2=current, 3=upcoming
}

interface Props {
    steps: Array<Step>
}

export default function StepProgressBar({steps}: Props) {
    return (
        <nav aria-label="Progress w-full">
            <ol role="list" className="md:flex py-4 gap-4 bg-gray-50 md:px-8 md:pb-0 rounded-xl">
                {steps.map((step) => (
                    <li key={step.name} className="md:flex-1">
                        {step.current ? (
                            <a
                                // onClick={() => step.onClick(step)}
                                aria-current="step"
                                className="flex flex-col border-l-2 border-primary py-2 pl-4 md:border-l-0 md:border-b-2 md:pb-0 md:pl-0 md:pb-4 flex-1"
                            >
                                <span className="text-sm font-medium text-primary">Step {step.id}</span>
                                <span className="text-sm font-medium">{step.name}</span>
                            </a>
                        ) : (
                            <a
                                // onClick={() => step.onClick(step)}
                                className="group   flex flex-col border-l-2 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-b-2 md:pb-0 md:pl-0 md:pb-4 flex-1"
                            >
                                <span
                                    className="text-sm font-medium text-gray-500 group-hover:text-gray-700">Step {step.id}</span>
                                <span className="text-sm font-medium">{step.name}</span>
                            </a>
                        )}
                    </li>
                ))}
            </ol>
        </nav>
    )
}
