import {Role, RoleEnumFromString} from "./Role.ts";
import {UserBusinessFlagsService} from "../../lib/Services/UserBusinessFlagsService.ts";
import {BackendApi} from "../api.ts";

interface rawApiResponse {
    id: string,
    first_name: string,
    last_name: string,
    email: string,
    role: string
    businesses: Array<string>
}

export class User {
    constructor(
        readonly id: string,
        readonly firstName: string,
        readonly lastName: string,
        readonly email: string,
        readonly role: Role,
        readonly businesses: Array<string>,
        private token: string | null = null,
        private flagsService: UserBusinessFlagsService | null = null
    ) {
    }

    public static fromApiResponse(data: rawApiResponse, api: BackendApi): User {
        const user = new User(
            data['id'],
            data['first_name'],
            data['last_name'],
            data.email,
            RoleEnumFromString(data["role"]),
            data['businesses']
        )

        const flag = new UserBusinessFlagsService(data['flags'], api)

        return user.withToken(api.token).withBusinessFlagsService(flag)
    }

    public withToken(token: string): User {
        return new User(
            this.id,
            this.firstName,
            this.lastName,
            this.email,
            this.role,
            this.businesses,
            token
        )
    }

    public withBusinessFlagsService(service: UserBusinessFlagsService): User {
        return new User(
            this.id,
            this.firstName,
            this.lastName,
            this.email,
            this.role,
            this.businesses,
            this.token,
            service
        )
    }

    get flags(): UserBusinessFlagsService {
        if (this.flagsService === null)
            throw new Error('.withBusinessFlagsService(...) has likely not been called, so the user object does not have a flags service')

        return this.flagsService
    }

    public getToken() {
        if (this.token === null)
            throw new Error('.withToken(...) has likely not been called, so the user object does not have a token')

        return this.token
    }

    public getFullName(): string {
        return `${this.firstName} ${this.lastName}`;
    }

}
