import {User} from "./api/accessControl/User.ts";
import {OnboardingFormPage} from "./pages/onboarding/OnboardingFormPage.tsx";
import {Login} from "./pages/login/Login.tsx";
import MyProfile from "./pages/app/shared/MyProfile.tsx";
import {Role} from "./api/accessControl/Role.ts";
import DashboardAdmin from "./pages/app/admin/DashboardAdmin.tsx";
import BaseTransactions from "./pages/app/admin/pages/baseTransactions/BaseTransactions.tsx";
import TransactionSpecifics from "./pages/app/admin/pages/transactionSpecifics/TransactionSpecifics.tsx";
import Export from "./pages/app/admin/pages/Export.tsx";
import UsersListPage from "./pages/app/admin/pages/UsersListPage.tsx";
import ImportJobsPage from "./pages/app/admin/pages/ImportJobsPage.tsx";
import CategorizationRulesPage from "./pages/app/admin/pages/categorizationRulesPage/CategorizationRulesPage.tsx";
import PartnerDashboard from "./pages/app/partner/PartnerDashboard.tsx";
import {BackendApi} from "./api/api.ts";
import {CustomerBankAccountRepository} from "./api/customerBankAccounts/CustomerBankAccountRepository.ts";
import DashboardEntrepreneur from "./pages/app/entrepreneur/pages/DashboardEntrepreneur.tsx";
import CashflowPage from "./pages/app/entrepreneur/pages/CashflowPage/CashflowPage.tsx";
import InsightsPage from "./pages/app/entrepreneur/pages/InsightsPage.tsx";
import {TransactionsPage} from "./pages/app/entrepreneur/pages/TransactionsPage/TransactionsPage.tsx";
import TaxesPage from "./pages/app/entrepreneur/pages/TaxesPage.tsx";
import BanksPage from "./pages/app/entrepreneur/pages/BanksPage.tsx";
import {BankConnectionCallBackPage} from "./pages/bankAccountConnectionFlow/BankConnectionCallBackPage.tsx";
import {ConnectABankPage} from "./pages/bankAccountConnectionFlow/ConnectABankPage.tsx";
import {SyncBankDataPage} from "./pages/bankAccountConnectionFlow/SyncBankDataPage.tsx";
import React from "react";
import {TFICRouter} from "./lib/TFICRouter.tsx";
import CategorizationReviewPage from "./pages/app/admin/pages/categorizationReviewPage/CategorizationReviewPage.tsx";
import URLCallbackRegisterer from "./pages/URLCallbackRegisterer.tsx";
import {BalancePage} from "./pages/app/entrepreneur/pages/BalancePage/BalancePage.tsx";
import BusinessesOverviewPage from "./pages/app/admin/pages/businessesOverviewPage/BusinessesOverviewPage.tsx";
import {ReconnectBankSuccessPage} from "./pages/bankAccountConnectionFlow/ReconnectBankSuccessPage.tsx";

async function userHasConnectedAtLeastOneBankAccount(api: BackendApi, user: User | (User & { businesses: unknown })): Promise<boolean> {
    const customerBankAccountRepository = new CustomerBankAccountRepository(api)

    const userHasABusiness = 'businesses' in user && user.businesses.length > 0
    if (!userHasABusiness)
        return false

    const bankAccounts = await customerBankAccountRepository.getMyBankAccounts(user.businesses[0], false)
    return bankAccounts.length > 0
}

export async function resolveRoutesForAuthState(user: User | null) {

    const router = new TFICRouter(user)

    router.registerCondition({name: 'AlwaysVisible', conditionEval: (user) => true})

    router.registerRoute('/integrations/exact/oauth-callback', 'AlwaysVisible', (user) => <URLCallbackRegisterer/>)


    router.registerCondition({name: 'UserIsNotLoggedIn', conditionEval: (user) => user === null})
    router.registerRoute('/onboarding', 'UserIsNotLoggedIn', (user) => <OnboardingFormPage/>)
    router.registerRoute('*', 'UserIsNotLoggedIn', (user) => <Login/>)


    router.registerCondition({name: 'AnyRole', conditionEval: (user) => user !== null})
    router.registerRoute('/my-profile', 'AnyRole', (user) => <MyProfile currentUser={user}/>)


    router.registerCondition({name: 'IsAdmin', conditionEval: (user) => user !== null && user.role === Role.Admin})
    router.registerRoute('/', 'IsAdmin', (user) => <DashboardAdmin currentUser={user}/>)
    router.registerRoute('/base-transactions', 'IsAdmin', (user) => <BaseTransactions currentUser={user}/>)
    router.registerRoute('/base-transactions/:transactionId', 'IsAdmin', (user) => <TransactionSpecifics
        currentUser={user}/>)
    router.registerRoute('/export', 'IsAdmin', (user) => <Export currentUser={user}/>)
    router.registerRoute('/users-list', 'IsAdmin', (user) => <UsersListPage currentUser={user}/>)
    router.registerRoute('/businesses', 'IsAdmin', (user) => <BusinessesOverviewPage currentUser={user}/>)
    router.registerRoute('/import-jobs', 'IsAdmin', (user) => <ImportJobsPage currentUser={user}/>)
    router.registerRoute('/categorization-rules', 'IsAdmin', (user) => <CategorizationRulesPage currentUser={user}/>)
    router.registerRoute('/categorization-review', 'IsAdmin', (user) => <CategorizationReviewPage currentUser={user}/>)

    router.registerCondition({name: 'IsPartner', conditionEval: (user) => user !== null && user.role === Role.Partner})
    router.registerRoute('*', 'IsPartner', (user) => <PartnerDashboard currentUser={user}/>)

    router.registerCondition({
        name: 'IsEntrepreneur',
        conditionEval: (user) => user !== null && user.role === Role.Entrepreneur
    })

    router.registerCondition({
        name: 'IsEntrepreneurAndHasBankAccounts', conditionEval: async (user) => {
            const isEntrepreneur = user !== null && user.role === Role.Entrepreneur
            if (!isEntrepreneur)
                return false

            const api = new BackendApi(user.getToken())
            return await userHasConnectedAtLeastOneBankAccount(api, user);
        }
    })


    router.registerRoute(
        '/',
        'IsEntrepreneurAndHasBankAccounts',
        (user) => <DashboardEntrepreneur currentUser={user}/>
    )
    router.registerRoute(
        '/cash-flow',
        'IsEntrepreneurAndHasBankAccounts',
        (user) => <CashflowPage currentUser={user}/>
    )
    router.registerRoute(
        '/balance',
        'IsEntrepreneurAndHasBankAccounts',
        (user) => <BalancePage currentUser={user}/>
    )
    router.registerRoute(
        '/insights',
        'IsEntrepreneurAndHasBankAccounts',
        (user) => <InsightsPage currentUser={user}/>
    )
    router.registerRoute(
        '/transactions',
        'IsEntrepreneurAndHasBankAccounts',
        (user) => <TransactionsPage currentUser={user}/>
    )
    router.registerRoute(
        '/taxes',
        'IsEntrepreneurAndHasBankAccounts',
        (user) => <TaxesPage currentUser={user}/>
    )
    router.registerRoute(
        '/banks',
        'IsEntrepreneurAndHasBankAccounts',
        (user) => <BanksPage currentUser={user}/>
    )

    router.registerCondition({
        name: 'IsEntrepreneurAndDoesNotHaveBankAccounts',
        conditionEval: async (user) =>{
            const isEntrepreneur = user !== null && user.role === Role.Entrepreneur
            if (!isEntrepreneur)
                return false

            const api = new BackendApi(user.getToken())
            return !(await userHasConnectedAtLeastOneBankAccount(api, user));
        }
    })

    router.registerRoute(
        '/bank-connection-callback',
        'IsEntrepreneur',
        (user) => <BankConnectionCallBackPage currentUser={user}/>
    )

    router.registerRoute(
        '/full-sync-bank-data',
        'IsEntrepreneur',
        (user) => <SyncBankDataPage currentUser={user}/>
    )

    router.registerRoute(
        '/sync-bank-data-after-reconnect',
        'IsEntrepreneur',
        (user) => <ReconnectBankSuccessPage currentUser={user}/>
    )

    router.registerRoute(
        '/connect-a-bank',
        'IsEntrepreneur',
        (user) => <ConnectABankPage currentUser={user}/>
    )

    router.registerRoute(
        '*',
        'IsEntrepreneurAndDoesNotHaveBankAccounts',
        (user) => <ConnectABankPage currentUser={user}/>
    )

    return await router.resolve()

}
