import {BaseTransaction} from "../../../api/baseTransactions/BaseTransaction.ts";
import {Money} from "../../../components/general/Money.tsx";

function TransactionRow({transaction}: { transaction: BaseTransaction }) {
    return (
        <tr className="text-sm text-gray-900">
            <td className='pb-4 px-4 leading-6'>
                <span className="text-lg">{transaction.relationName}</span><br/>
                <span className="text-base text-gray-700 pt-48">{transaction.iban}</span>
            </td>
            <td className='pb-4 px-4 text-gray-500'>{transaction.description}</td>
            <td className='pb-4 px-4'>
                <span
                    className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1  font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
                    <svg viewBox="0 0 6 6" aria-hidden="true"
                         className={(transaction.flow === 'inflow') ? 'fill-green-500 h-1.5 w-1.5' : 'fill-red-500 h-1.5 w-1.5'}>
                      <circle r={3} cx={3} cy={3}/>
                    </svg>
                    {transaction.category}
                </span>

            </td>
            <td className='pb-4 px-4 text-gray-500'>{transaction.date}</td>
            <td className='pb-4 px-4 text-base'>€ <Money
                value={((transaction.amount / 100) * ((transaction.flow === 'outflow') ? -1 : 1))}/></td>
        </tr>
    )
}


export default function Transactions({transactions}: { transactions: Array<BaseTransaction> }) {

    return (
        <table className="border-collapse w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="bg-gray-50 rounded-xl">
            <tr className="text-base text-gray-900">
                <td className="px-4 py-2 rounded-s-md">Relatie</td>
                <td className="px-4 py-2">Omschrijving</td>
                <td className="px-4 py-2">Categorie</td>
                <td className="px-4 py-2">Datum</td>
                <td className="px-4 py-2 rounded-e-md">Bedrag</td>
            </tr>
            <tr className="h-2"></tr>
            </thead>
            <tbody>
            {transactions.map(transaction => <TransactionRow transaction={transaction}/>)}
            </tbody>
        </table>
    )
}
