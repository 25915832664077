import * as React from "react";

interface Props {
    title: string,
    onClick: () => void,
    className?: string,
    icon?: any;
}

export function PrimaryButton(props: Props) {

    return (
        <>
            <button
                type="button"
                onClick={props.onClick}
                className={"relative flex items-center leading-0 rounded-md bg-primary px-2 py-1 text-white transition hover:transition hover:opacity-75 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 " + props.className}
            >
                {props.icon && <props.icon
                    className='h-5 w-5 mb-0.5'
                />}
                {props.title}</button>
        </>

    )
}
