import {User} from "../api/accessControl/User.ts";

export class HotJar {
    public static tagUserSession(user: User): void {
        window?.hj?.('identify', user.id, {
            fullName: user.getFullName(),
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            role: user.role,
        });
    }
}

