import * as React from "react";

interface Props {
    title: string,
    onClick: () => void,
    className?: string,
    icon?: any;
    disabled?: boolean
}

export function SecondaryButton(props: Props) {

    const baseButtonStyles = "rounded-md px-2.5 py-1.5 text-sm font-semibold  ring-1 ring-inset ring-gray-300 hover:bg-gray-50 "
    const enabled = "bg-white text-gray-900 shadow-sm"
    const disabled = "bg-gray-50 text-gray-700 shadow-sm cursor-not-allowed"

    const finalStyles = baseButtonStyles + " " + (props.disabled ? disabled : enabled) + " " + props.className

    return (
        <>
            <button
                type="button"
                onClick={props.onClick}
                disabled={props.disabled}
                className={finalStyles}
            >
                {props.icon && <props.icon
                    className='h-5 w-5 mb-0.5'
                />}
                {props.title}</button>
        </>

    )
}
