// @ts-nocheck
import Shell from "../../../components/specific/Shell.tsx";
import {User} from "../../../api/accessControl/User.ts";


export default function DashboardAdmin({currentUser}: { currentUser: User }) {

    return (
        <>
            <Shell currentUser={currentUser}>
                <h1 className="weight-600 text-3xl mb-8">Hello {currentUser.firstName}</h1>
                <div className="flex gap-4">
                    <iframe
                        src="https://redash.tfic.cloud/embed/query/2/visualization/4?api_key=kkpQtBuxPYOHTkGCECrRtBhNf13EKFSwP6izX0Zw&"
                        className='w-[50%] h-64'></iframe>
                    <iframe
                        src="https://redash.tfic.cloud/embed/query/1/visualization/2?api_key=MdiTsNuhHgboR9XMIgJty8TqJU0WYAkMVwQJLw3X&"
                        className='w-[50%] h-64'></iframe>
                </div>
                <iframe
                    src="https://redash.tfic.cloud/embed/query/5/visualization/10?api_key=9nmo1YiaY4x9qPBpkNIg0SAC2ycYnijGSG1bQWVs&"
                    className='w-[100%] h-[60vh]'></iframe>

            </Shell>


        </>
    )
}
