export const authErrorsNl = {
    "admin-restricted-operation": "Deze bewerking is alleen voor beheerders toegestaan.",
    "argument-error": "",
    "app-not-authorized": "Deze app, geïdentificeerd door het domein waar het gehost is, is niet geautoriseerd om Firebase Authentication te gebruiken met de verstrekte API-sleutel. Controleer de sleutelconfiguratie in de Google API-console.",
    "app-not-installed": "De gevraagde mobiele applicatie die overeenkomt met de opgegeven identifier (Android-pakketnaam of iOS-bundle-ID) is niet geïnstalleerd op dit apparaat.",
    "captcha-check-failed": "De reCAPTCHA-responstoken die is verstrekt is ongeldig, verlopen, al gebruikt of het domein waar deze aan gekoppeld is komt niet overeen met de lijst van goedgekeurde domeinen.",
    "code-expired": "De SMS-code is verlopen. Stuur de verificatiecode opnieuw om het opnieuw te proberen.",
    "cordova-not-ready": "Het Cordova-framework is niet klaar.",
    "cors-unsupported": "Deze browser wordt niet ondersteund.",
    "credential-already-in-use": "Deze referentie is al gekoppeld aan een ander gebruikersaccount.",
    "custom-token-mismatch": "Het op maat gemaakte token komt overeen met een andere doelgroep.",
    "requires-recent-login": "Deze bewerking is gevoelig en vereist recente authenticatie. Log opnieuw in voordat u deze aanvraag opnieuw probeert.",
    "dynamic-link-not-activated": "Activeer Dynamic Links in de Firebase-console en ga akkoord met de voorwaarden.",
    "email-change-needs-verification": "Multi-factor gebruikers moeten altijd een geverifieerd e-mailadres hebben.",
    "email-already-in-use": "Het e-mailadres wordt al gebruikt door een ander account.",
    "expired-action-code": "De actiecode is verlopen.",
    "cancelled-popup-request": "Deze bewerking is geannuleerd vanwege een andere conflicterende popup die geopend is.",
    "internal-error": "Er is een interne fout opgetreden.",
    "invalid-app-credential": "Het telefoonverificatieverzoek bevat een ongeldige applicatieverifier. De reCAPTCHA-tokenrespons is ongeldig of verlopen.",
    "invalid-app-id": "De mobiele app-ID is niet geregistreerd voor het huidige project.",
    "invalid-user-token": "De gebruikersreferentie is niet geldig voor dit project. Dit kan gebeuren als het token van de gebruiker is gemanipuleerd of als de gebruiker niet voor het project geassocieerd met deze API-sleutel is.",
    "invalid-auth-event": "Er is een interne fout opgetreden.",
    "invalid-verification-code": "De SMS-verificatiecode die is gebruikt om het telefoonverificatiebewijs te maken is ongeldig. Stuur de verificatiecode opnieuw en gebruik de door de gebruiker verstrekte verificatiecode.",
    "invalid-continue-uri": "De vervolg-URL die in het verzoek is verstrekt is ongeldig.",
    "invalid-cordova-configuration": "De volgende Cordova-plugins moeten geïnstalleerd zijn om OAuth-aanmelding mogelijk te maken: cordova-plugin-buildinfo, cordova-universal-links-plugin, cordova-plugin-browsertab, cordova-plugin-inappbrowser en cordova-plugin-customurlscheme.",
    "invalid-custom-token": "Het aangepaste tokenformaat is onjuist. Controleer de documentatie.",
    "invalid-dynamic-link-domain": "Het opgegeven dynamische linkdomein is niet geconfigureerd of geautoriseerd voor het huidige project.",
    "invalid-email": "Het e-mailadres is verkeerd geformatteerd.",
    "invalid-api-key": "Je API-sleutel is ongeldig, controleer of je deze correct hebt gekopieerd.",
    "invalid-cert-hash": "De verstrekte SHA-1-certificaat-hash is ongeldig.",
    "invalid-credential": "De gebruikersnaam of het wachtwoord is onjuist.",
    "invalid-message-payload": "De e-mailsjabloon die overeenkomt met deze actie bevat ongeldige tekens in het bericht. Los dit op door naar de sectie E-mail sjablonen in de Firebase Console te gaan.",
    "invalid-multi-factor-session": "Het verzoek bevat geen geldig bewijs van succesvolle eerste factor-aanmelding.",
    "invalid-oauth-provider": "EmailAuthProvider wordt niet ondersteund voor deze bewerking. Deze bewerking ondersteunt alleen OAuth-aanbieders.",
    "invalid-oauth-client-id": "De verstrekte OAuth-client-ID is ongeldig of komt niet overeen met de opgegeven API-sleutel.",
    "unauthorized-domain": "Dit domein is niet geautoriseerd voor OAuth-bewerkingen voor je Firebase-project. Bewerk de lijst van geautoriseerde domeinen in de Firebase-console.",
    "invalid-action-code": "De actiecode is ongeldig. Dit kan gebeuren als de code verkeerd is, verlopen is of al gebruikt is.",
    "wrong-password": "Het wachtwoord is ongeldig of de gebruiker heeft geen wachtwoord.",
    "invalid-persistence-type": "Het opgegeven persistentie-type is ongeldig. Het kan alleen lokaal, sessie of geen zijn.",
    "invalid-phone-number": "Het formaat van het opgegeven telefoonnummer is onjuist. Voer het telefoonnummer in een formaat in dat kan worden geparsed naar E.164-formaat. E.164-telefoonnummers worden geschreven in het formaat [+][landcode][abonneenummer inclusief netnummer].",
    "invalid-provider-id": "De opgegeven provider-ID is ongeldig.",
    "invalid-recipient-email": "De e-mail die overeenkomt met deze actie is niet verzonden omdat het verstrekte ontvanger-e-mailadres ongeldig is.",
    "invalid-sender": "De e-mailsjabloon die overeenkomt met deze actie bevat een ongeldige afzender-e-mail of naam. Los dit op door naar de sectie E-mail sjablonen in de Firebase Console te gaan.",
    "invalid-verification-id": "De verificatie-ID die is gebruikt om het telefoonverificatiebewijs te maken is ongeldig.",
    "invalid-tenant-id": "Het tenant-ID van de Auth instantie is ongeldig.",
    "multi-factor-info-not-found": "De gebruiker heeft geen tweede factor die overeenkomt met de verstrekte identifier.",
    "multi-factor-auth-required": "Bewijs van eigendom van een tweede factor is vereist om in te loggen.",
    "missing-android-pkg-name": "Een Android-pakketnaam moet worden verstrekt als de Android-app moet worden geïnstalleerd.",
    "auth-domain-config-required": "Zorg ervoor dat je authDomain opneemt bij het aanroepen van firebase.initializeApp(), volgens de instructies in de Firebase-console.",
    "missing-app-credential": "Het telefoonverificatieverzoek mist een applicatieverifier-verklaring. Een reCAPTCHA-responstoken moet worden verstrekt.",
    "missing-verification-code": "Het telefoonverificatiedocument is gemaakt met een lege SMS-verificatiecode.",
    "missing-continue-uri": "Een vervolg-URL moet worden verstrekt in het verzoek.",
    "missing-iframe-start": "Er is een interne fout opgetreden.",
    "missing-ios-bundle-id": "Een iOS-bundle-ID moet worden verstrekt als een App Store-ID is verstrekt.",
    "missing-multi-factor-info": "Er is geen tweede factor-identifier verstrekt.",
    "missing-multi-factor-session": "Het verzoek mist bewijs van een succesvolle eerste factor-aanmelding.",
    "missing-or-invalid-nonce": "Het verzoek bevat geen geldige nonce. Dit kan gebeuren als de SHA-256-hash van de verstrekte raw nonce niet overeenkomt met de gehashte nonce in de ID-token payload.",
    "missing-phone-number": "Om verificatiecodes te verzenden, geef een telefoonnummer op voor de ontvanger.",
    "missing-verification-id": "Het telefoonverificatiebewijs is gemaakt met een lege verificatie-ID.",
    "app-deleted": "Deze instantie van FirebaseApp is verwijderd.",
    "account-exists-with-different-credential": "Er bestaat al een account met hetzelfde e-mailadres maar verschillende inlogreferenties. Log in met een provider die geassocieerd is met dit e-mailadres.",
    "network-request-failed": "Er is een netwerkfout opgetreden (zoals time-out, onderbroken verbinding of niet-bereikbare host).",
    "no-auth-event": "Er is een interne fout opgetreden.",
    "no-such-provider": "De gebruiker was niet gekoppeld aan een account met de opgegeven provider.",
    "null-user": "Een null-gebruikerobject werd verstrekt als argument voor een bewerking die een niet-null gebruikerobject vereist.",
    "operation-not-allowed": "De opgegeven inlogprovider is uitgeschakeld voor dit Firebase-project. Zet het aan in de Firebase-console, onder het tabblad inlogmethode in de sectie Auth.",
    "operation-not-supported-in-this-environment": 'Deze bewerking wordt niet ondersteund in de omgeving waarin deze toepassing wordt uitgevoerd. "location.protocol" moet http, https of chrome-extension zijn en webopslag moet zijn ingeschakeld.',
    "popup-blocked": "Kon geen verbinding maken met de popup. Deze kan geblokkeerd zijn door de browser.",
    "popup-closed-by-user": "De popup is door de gebruiker gesloten voordat de bewerking is voltooid.",
    "provider-already-linked": "De gebruiker kan alleen worden gekoppeld aan één identiteit voor de opgegeven provider.",
    "quota-exceeded": "De quotum voor deze bewerking is overschreden voor dit project.",
    "redirect-cancelled-by-user": "De redirect-operatie is door de gebruiker geannuleerd voordat deze is voltooid.",
    "redirect-operation-pending": "Er is al een redirect-aanmeldoperatie in uitvoering.",
    "rejected-credential": "Het verzoek bevat verkeerd geformatteerde of niet-overeenkomende referenties.",
    "second-factor-already-in-use": "De tweede factor is al geregistreerd voor dit account.",
    "maximum-second-factor-count-exceeded": "Het maximale aantal toegestane tweede factoren voor een gebruiker is overschreden.",
    "tenant-id-mismatch": "De verstrekte tenant-ID komt niet overeen met de Auth-instantie tenant-ID.",
    timeout: "De bewerking is verlopen.",
    "user-token-expired": "Het gebruikersreferentie is niet meer geldig. De gebruiker moet opnieuw inloggen.",
    "too-many-requests": "Alle verzoeken vanaf dit apparaat zijn geblokkeerd vanwege ongebruikelijke activiteit. Probeer het later opnieuw.",
    "unauthorized-continue-uri": "Het domein van de vervolg-URL is niet op de witte lijst. Voeg het domein toe aan de lijst in de Firebase-console.",
    "unsupported-first-factor": "Het aanmelden met een tweede factor of het inschrijven in een multi-factor account vereist inloggen met een ondersteunde eerste factor.",
    "unsupported-persistence-type": "De huidige omgeving ondersteunt het opgegeven persistentie-type niet.",
    "unsupported-tenant-operation": "Deze bewerking wordt niet ondersteund in een multi-tenant-context.",
    "unverified-email": "De bewerking vereist een geverifieerd e-mailadres.",
    "user-cancelled": "De gebruiker heeft uw applicatie de gevraagde machtigingen niet verleend.",
    "user-not-found": "Er is geen gebruikerrecord voor deze identifier. De gebruiker is mogelijk verwijderd.",
    "user-disabled": "Het gebruikersaccount is uitgeschakeld door een beheerder.",
    "user-mismatch": "De opgegeven referenties komen niet overeen met de vorige ingelogde gebruiker.",
    "user-signed-out": "",
    "weak-password": "Het wachtwoord moet ten minste 6 tekens lang zijn.",
    "web-storage-unsupported": "Deze browser wordt niet ondersteund of 3rd party cookies en gegevens zijn mogelijk uitgeschakeld."
};
