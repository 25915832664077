import {
    ChartConfig,
    ChartContainer,
    ChartLegend,
    ChartLegendContent,
    ChartTooltip,
    ChartTooltipContent
} from "../../../../../components/general/chart.tsx";
import {Area, CartesianGrid, ComposedChart, XAxis} from "recharts";

const chartConfig = {
    balance: {
        label: "Saldo",
        color: "#45AEEA",
    },
    forecasted_balance: {
        label: "Forecast",
        color: "rgba(138,133,133,0.26)",
    },

} satisfies ChartConfig

export default function DailyBalanceGraph({data, className}) {

    if (!data) return;
    const reversedData = [...data].reverse();


    return (

        <div
            className={"p-8 rounded-lg bg-white md:grid-cols-3 border-2 border-gray-50 w-full " + (className || " ")}>

            <ChartContainer config={chartConfig} className="h-[100%] w-full">
                <ComposedChart accessibilityLayer data={reversedData}>
                    <CartesianGrid vertical={false}/>
                    <XAxis
                        dataKey="date"
                        tickLine={false}
                        tickMargin={10}
                        axisLine={false}
                    />

                    <ChartTooltip content={<ChartTooltipContent/>}/>
                    <ChartLegend content={<ChartLegendContent/>}/>
                    <Area type='monotone' dataKey="balance" fill="var(--color-balance)" radius={8}
                          onClick={(e) => console.log(e)} activeDot={{stroke: '#45AEEA', r: 10}}
                          strokeLinejoin="round"/>
                    <Area type='monotone' dataKey="forecasted_balance" fill="var(--color-forecasted_balance)" radius={8}
                          onClick={(e) => console.log(e)} stroke="#45AEEA" strokeWidth={2}
                          activeDot={{stroke: 'rgba(89,89,89,0.25)', r: 10}} strokeLinejoin="round"
                          strokeDasharray="10 10"/>


                </ComposedChart>

            </ChartContainer>
        </div>


    );
}

