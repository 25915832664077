import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {BaseTransaction} from "../../../../../api/baseTransactions/BaseTransaction.ts";
import {User} from "../../../../../api/accessControl/User.ts";
import {BackendApi} from "../../../../../api/api.ts";
import {BaseTransactionsRepository} from "../../../../../api/baseTransactions/BaseTransactionsRepository.ts";
import TransactionSpecs from "../../TransactionSpecs.tsx";
import Shell from "../../../../../components/specific/Shell.tsx";

export default function Transaction({currentUser}: { currentUser: User }) {
    const params = useParams()
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [transaction, setTransaction] = useState<BaseTransaction | null>(null)

    const api = new BackendApi(currentUser.getToken());

    async function onLoad() {
        const baseTransactionsRepository = new BaseTransactionsRepository(api);
        const result = await baseTransactionsRepository.getCustomerBankAccountTransaction(params.transactionId)
        setTransaction(result.results);
        setIsLoading(false);
    }

    useEffect(() => {
        onLoad()
    }, [])

    return (
        <>
            <Shell currentUser={currentUser} isLoading={isLoading}>
                <TransactionSpecs transaction={transaction} queryTransactionHistory={async (transactionId) => {
                    const result = await api.get('transactions/get-transaction-history', {transaction_id: transactionId})
                    return await result.json()
                }}></TransactionSpecs>
            </Shell>
        </>
    )
}
