import {CustomerBankAccount} from "../../api/customerBankAccounts/CustomerBankAccount.ts";
import {Money} from "../general/Money.tsx";
import {Link} from "react-router-dom";


interface Props {
    customerBankAccount: CustomerBankAccount,
    onRenewButtonClick?: (bankId: string) => void
}

export default function BankCard({customerBankAccount, onRenewButtonClick}: Props) {
    if (customerBankAccount.expiresSoon)
        return (
            <div className="group">

                <div
                    className="border-2 border-red-400 rounded-xl p-8 w-96 relative aspect-video bg-gradient-to-r from-blue-200 via-gray-200 to-primary/50  transition  hover:border-primary/40 ">
                    <div className="flex justify-between">
                        <div>
                            <div>
                                <h3 className=" font-mono mt-2 text-xs">Name</h3>
                                <h2 className="uppercase font-mono">{customerBankAccount.ownerName}</h2>
                            </div>
                            <div>
                                <h3 className=" font-mono mt-2 text-xs">IBAN</h3>
                                <h2 className="uppercase font-mono">{customerBankAccount.iban}</h2>
                            </div>
                        </div>

                        <img src={customerBankAccount.logoUrl}
                             alt="Institution logo" className="h-20 rounded-xl mix-blend-multiply"/>
                    </div>

                    <div>
                        <h3 className=" font-mono mt-2 text-xs">Balance</h3>
                        {customerBankAccount.isExpired &&
                            <h2 className="uppercase font-mono text ">⚠️ Connectie verlopen</h2>}
                        {!customerBankAccount.isExpired && <h2 className="uppercase font-mono text "><Money
                            value={customerBankAccount.balance / 100}/> - VERLOOPT BINNENKORT</h2>}
                    </div>

                    <div className="absolute top-0 right-0 bg-primary p-2 px-4 m-3 rounded-md shadow-md">
                        <button onClick={() => onRenewButtonClick(customerBankAccount.institutionName)}
                                className="text-white">Vernieuwen
                        </button>
                    </div>
                </div>
            </div>
        )


    return (
        <div className="group">

            <div
                className="border-2 border-gray-200 rounded-xl p-8 w-96 aspect-video bg-gradient-to-r from-blue-200 via-gray-200 to-primary/50  transition  hover:border-primary/40 ">
                <div className="flex justify-between">
                    <div>
                        <div>
                            <h3 className=" font-mono mt-2 text-xs">Name</h3>
                            <h2 className="uppercase font-mono">{customerBankAccount.ownerName}</h2>
                        </div>
                        <div>
                            <h3 className=" font-mono mt-2 text-xs">IBAN</h3>
                            <h2 className="uppercase font-mono">{customerBankAccount.iban}</h2>
                        </div>
                    </div>

                    <img src={customerBankAccount.logoUrl}
                         alt="Institution logo" className="h-20 rounded-xl mix-blend-multiply"/>
                </div>

                <div>
                    <h3 className=" font-mono mt-2 text-xs">Balance</h3>
                    <h2 className="uppercase font-mono text-2xl "><Money value={customerBankAccount.balance / 100}/>
                    </h2>
                </div>
            </div>
        </div>
    )
}
