import {User} from "../../../../api/accessControl/User.ts";
import {useEffect, useState} from "react";
import {BackendApi} from "../../../../api/api.ts";
import LoadingFrame from "../../../../components/LoadingFrame.tsx";
import {Menu, MenuButton, MenuItems} from "@headlessui/react";
import {ChevronDownIcon} from "@heroicons/react/16/solid/index";
import Shell from "../../../../components/specific/Shell.tsx";

export default function UsersListPage({currentUser}: { currentUser: User }) {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [users, setUsers] = useState<any>([])

    async function onLoad() {
        const api = new BackendApi(currentUser.getToken());
        api.get('access-control/users', {}).then(data => data.json()).then(data => setUsers(data)).then(() => setIsLoading(false))
    }

    async function sendEmailVerificationEmail(user_id: string) {
        const api = new BackendApi(currentUser.getToken());
        api.post('access-control/user/send-email-verification-email', {
            user_id: user_id
        }).then(() => alert('Success: Sent!')).catch(err => alert('Something went wrong. Please contact us via hello@pocketcfo.io | CODE: ERR52436 | err' + err))
    }

    useEffect(() => {
        onLoad()
    }, [])


    return (
        <div>
            <Shell currentUser={currentUser} isLoading={isLoading}>
                <h1 className="weight-600 text-3xl mb-6 ">Users</h1>
                <LoadingFrame isLoading={isLoading}>
                    <table className="mt-6 w-full whitespace-nowrap text-left">
                        <colgroup>
                            <col className="w-full sm:w-4/12"/>
                            <col className="lg:w-4/12"/>
                            <col className="lg:w-2/12"/>
                            <col className="lg:w-1/12"/>
                            <col className="lg:w-1/12"/>
                        </colgroup>

                        <thead className="bg-gray-50 rounded-xl">

                        <tr className="text-base text-gray-900">
                            <th className="rounded-s-md px-4 py-2">
                                Name
                            </th>
                            <th className="px-4 py-2">
                                Email
                            </th>
                            <th className="px-4 py-2">
                                Business
                            </th>
                            <th className="px-4 py-2">
                                Role
                            </th>
                            <th className="px-4 py-2 rounded-e-md ">
                                Actions
                            </th>
                        </tr>
                        </thead>
                        <tbody className="divide-y divide-white/5">
                        {users.map((user) => (
                            <>
                                <tr className="h-2"></tr>
                                <tr key={user.id} className='bg-gray-50 hover:bg-gray-100 transition'>
                                    <td className="rounded-s-md px-4 py-2">
                                        <span
                                            className="text-sm leading-6 ">{user.first_name} {user.last_name}</span><br/>
                                        <span className="font-mono text-gray-500 text-xs">{user.id}</span>
                                    </td>
                                    <td className="px-4">
                                        <span className="text-sm leading-6 ">{user.email}</span>
                                    </td>
                                    <td className="px-4">
                                        <span className="text-sm leading-6 ">{user.business_name}</span>
                                    </td>
                                    <td className="px-4">
                                        <span className="text-sm leading-6 ">{user.role_name}</span>
                                    </td>
                                    <td className="px-4 py-2 rounded-e-md ">
                                        <div className="inline-flex rounded-md shadow-sm text-xs">
                                            <button
                                                type="button"
                                                onClick={() => sendEmailVerificationEmail(user.id)}
                                                className="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                                            >
                                                Send email verification
                                            </button>
                                            <Menu as="div" className="relative -ml-px block">
                                                <MenuButton
                                                    className="relative inline-flex items-center rounded-r-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
                                                    <span className="sr-only">Open options</span>
                                                    <ChevronDownIcon aria-hidden="true" className="h-5 w-5"/>
                                                </MenuButton>
                                                <MenuItems
                                                    transition
                                                    className="absolute right-0 z-10 -mr-1 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                                                >
                                                    <div className="py-1 px-2">
                                                        Nothing here
                                                    </div>
                                                </MenuItems>
                                            </Menu>
                                        </div>
                                    </td>
                                </tr>
                            </>

                        ))}
                        </tbody>
                    </table>
                </LoadingFrame>
            </Shell>

        </div>
    )
}
