import {User} from "../../api/accessControl/User.ts";
import {Link, useSearchParams} from "react-router-dom";
import LoadingFrame from "../../components/LoadingFrame.tsx";
import {useEffect, useState} from "react";
import {BackendApi} from "../../api/api.ts";
import {Header} from "../../components/general/Header.tsx";

export function BankConnectionCallBackPage({currentUser}: { currentUser: User }) {
    const [searchParams, setSearchParams] = useSearchParams();

    const [isLoading, setIsLoading] = useState<boolean>(true);

    async function onLoad() {
        const api = new BackendApi(currentUser.getToken())

        let response;

        if (searchParams.get('error')) {
            response = await api.post('mark-bank-connection-as-failed', {
                reference_id: searchParams.get('ref'),
                context: {
                    error: searchParams.get('error'),
                    details: searchParams.get('details') || ''
                }
            })
            return;

        } else {
            response = await api.post('mark-bank-as-connected', {
                reference_id: searchParams.get('ref')
            })
        }

        const urlToRedirectTo = await api.OpenBanking__handleCallback({
            ref_id: searchParams.get('ref'),
        })

        if (response.status !== 200) {
            alert('Something went wrong. Please contact us via hello@pocketcfo.io | CODE: ERR52436')
            return
        } else {
            window.location.replace(urlToRedirectTo.redirect_url + '?ref=' + searchParams.get('ref'))
        }
    }

    useEffect(() => {
        onLoad()
    }, []);


    if (searchParams.get('error')) {
        return (
            <div className="rounded-md bg-red-50 p-4 my-8 max-w-3xl mx-auto my-8">
                <div className="flex">
                    <div className="flex-shrink-0">
                        <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor"
                             aria-hidden="true">
                            <path fillRule="evenodd"
                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
                                  clipRule="evenodd"/>
                        </svg>
                    </div>
                    <div className="ml-3">
                        <h3 className="text-sm font-medium text-red-700">Something went wrong!</h3>
                        <div className="mt-2 text-sm text-red-700">
                            <p>Something went wrong with connecting to the bank. Error: "{searchParams.get('error')}".
                                We've been notified and will reach out
                                to you shortly to fix the issue!</p>

                            <Link to={'/connect-a-bank'}
                                  className="inline-flex mt-4  items-center rounded-md bg-red-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary">
                                Try again
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    return (
        <div>
            <Header/>
            <main className="bg-white py-24 sm:py-32">
                <LoadingFrame isLoading={isLoading}>
                    <div className="mx-auto max-w-7xl px-6 lg:px-8">


                        <div className="mx-auto max-w-2xl text-center">

                            <div className="flex items-center flex-col">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     stroke-width="1.5"
                                     stroke="currentColor" className="size-24 text-green-400">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                          d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                                </svg>
                            </div>
                            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Bank
                                connected
                                successfully!</p>
                            <p className="mt-6 text-lg leading-8 text-gray-600">
                                If you want to add another business bank account,
                                even from another bank, you can click on the button below.
                                This way, we will combine all your accounts, so that you
                                can see your cash balance and transactions in one place.</p>
                        </div>


                    </div>
                    <div className="flex justify-center w-full mt-16">
                        <Link to={'/full-sync-bank-data?ref=' + searchParams.get('ref')}
                              className="inline-flex items-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary">
                            Continue &rarr;
                        </Link>
                    </div>
                </LoadingFrame>
            </main>
        </div>
    )
}
