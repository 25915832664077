import {useEffect, useState} from 'react';
import {BackendApi} from '../api/api.ts';
import {User} from "../api/accessControl/User.ts";

export interface InsightReadReceipt {
    id: string;
}

export const useInsightReadReceipt = (user: User) => {
    const [readReceipts, setReadReceipts] = useState<InsightReadReceipt[]>([]);
    const [hasUnreadInsights, setHasUnreadInsights] = useState(false);

    useEffect(() => {
        fetchReadReceipts();
    }, [user]);

    const fetchReadReceipts = async () => {
        try {
            const api = new BackendApi(user.getToken());
            const response = await api.get('insights/unread-insights');

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            if (Array.isArray(data?.ids)) {
                setReadReceipts(data);
                const unreadInsights = data?.ids.length > 0;
                setHasUnreadInsights(unreadInsights);
            } else {
                console.error('Unexpected response format:', data);
            }
        } catch (error) {
            console.error('Error fetching insight read receipts:', error);
        }
    };

    return {readReceipts, hasUnreadInsights};
};
